import axios from "axios";
import React, { useContext, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { Button, CircularProgress } from "@mui/material";

const LoginPage = () => {
 
  
 const [showPassword, setShowPassword] = useState(false);
 const [EMailAddress, setEMailAddress] = useState("");
 const [passWord, setPassWord] = useState("");
 const [isLoginFailed, setIsLoginFailed] = useState(false);
 let navigate = useNavigate();
 const [isLoading, setIsLoading] = useState(false);

 const togglePasswordVisibility = () => {
   setShowPassword(!showPassword);
 };

 const login = async () => {
   setIsLoading(true);
   try{
   let response = await axios.post('https://api.triphog.net/api/v1/superadmin/login', { EMailAddress, passWord });
   console.log(response.data);
   if (response.data.success) {
     localStorage.setItem("superadmintoken", response.data.token);
     localStorage.setItem("superAdminId", response.data.superAdminId);
     localStorage.removeItem("token");
     toast.success("Successfully Logged In!", { position: "top-center" });
    
     setIsLoading(false);
     window.location.href = "/superadmin";
   } else {
     toast.error("Invalid EMail OR PassWord", { position: "top-center" });
     setTimeout(() => {
       setIsLoading(false);
       setIsLoginFailed(true);
     }, 500);
   }
  }
  catch(e){
    toast.error(e.message)
    setIsLoginFailed(true)
  }
  finally{
    setIsLoading(false)
  }
 };

 return (
   <div className="flex flex-col lg:flex-row h-screen">
     <div className="lg:flex-1 hidden lg:flex flex-col bg-deepBlue text-white">
       <div className="p-4">
         <h1 style={{fontSize:"30px"}}>TRIPHOG</h1>
       </div>
       <ToastContainer />
       <div className="flex flex-1 justify-center items-center">
         <div className="w-64 h-64 bg-[#252749] rounded-full flex items-center justify-center">
           <img src={require("./appimage.jpeg")} className="rounded-full" />
         </div>
       </div>
     </div>

     <div className="flex-1 flex flex-col justify-center items-center p-4 lg:p-0">
       <ToastContainer />
       <div>
         <img
           src="/uploads/login.jpg"
           alt="login "
           className="lg:hidden w-full object-cover"
         />
       </div>
       <div className="bg-white p-8 rounded-lg w-full lg:w-[75%]">
         <h2 className="text-xl font-bold mb-6 text-center">Welcome</h2>
         <div className="mb-4 relative text-lg">
           <input
             type="email"
             placeholder="Email / Username"
             onChange={(e) => setEMailAddress(e.target.value)}
             className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10" // Added padding-left here
           />
           <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
             <MdOutlineEmail />
           </span>
         </div>
         <div className="mb-4 relative text-lg">
           <input
             type={showPassword ? "text" : "password"}
             placeholder="Password"
             onChange={(e) => setPassWord(e.target.value)}
             className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10" // Added padding-left here
           />
           <span className="absolute inset-y-0 left-0 flex items-center text-gray-400 pl-3">
             <CiLock />
           </span>
           <button
             type="button"
             onClick={togglePasswordVisibility}
             className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
           >
             {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
           </button>
         </div>
         <div className="mb-4">
           <Link to="/superadmin/forgotpassword" className="text-indigo-500 hover:text-indigo-700 float-right mb-2">
             Forgot password?
           </Link>
         </div>
         
         {isLoading ? (
           <button className="w-full bg-deepBlue text-white py-4 rounded-full transition-colors duration-300">
             <CircularProgress sx={{ height: "20px", width: "20px", color: "white", margin: "auto" }} />
           </button>
         ) : (
           <button className="w-full bg-deepBlue text-white py-4 rounded-full transition-colors duration-300" onClick={login}>
             Log in
           </button>
         )}
       </div>
     </div>
   </div>
 );
};

export default LoginPage;
