import { IoArrowBackOutline } from "react-icons/io5";
// import { useAddNewAdminContext } from "../../../../Providers/SuperAdmin/AddNewAdminProvider";
import { MdLinkedCamera } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
// import AdminSideBar from "./AddNewAdminSideBar";
import { useLoadScript,Autocomplete } from "@react-google-maps/api";
import Modal from "../../../Modal";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
const libraries = ['places'];


const AddNewRide = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCRDiod9UUH0SHTiIDg2k3fW_FtO0EekSg',
    libraries,
  });
  const [isOtherOptionSelected,setIsOtherOptionSelected]=useState(false)
  const pickupAutocompleteRef = useRef();
  const dropoffAutocompleteRef = useRef();

  const onLoadPickup = (autocomplete) => {
    pickupAutocompleteRef.current = autocomplete;
  };

  const onLoadDropoff = (autocomplete) => {
    dropoffAutocompleteRef.current = autocomplete;
  };

  const onPlaceChangedPickup = () => {
    if (pickupAutocompleteRef.current !== null) {
      const place = pickupAutocompleteRef.current.getPlace();
      setPickUpAddress(place.formatted_address);
    }
  };

  const onPlaceChangedDropoff = () => {
    if (dropoffAutocompleteRef.current !== null) {
      const place = dropoffAutocompleteRef.current.getPlace();
      setDropOffAddress(place.formatted_address);
    }
  };

  const[patientName,setPatientName]=useState("")
  const[patientPhoneNumber,setPatientPhoneNumber]=useState("")
  const[patientRef,setPatientRef]=useState("")
  const[pickUpDate,setPickUpDate]=useState("")
  const[pickUpTime,setPickUpTime]=useState("")
  const[appointmentTime,setAppointmentTime]=useState("") 
  const[pickUpAddress,setPickUpAddress]=useState("")
  const[dropOffAddress,setDropOffAddress]=useState("") 
  const[legId,setLegId]=useState("")
 
  const[patientType,setPatientType]=useState("Wheel Chair")
  
  const[patients,setPatients]=useState([])
  let navigate=useNavigate()
  let getAllPatients=async()=>{
    const token=localStorage.getItem("token")
    let response=await axios.get("https://api.triphog.net/api/v1/patient/getall",{headers:{'Authorization':token}})
    console.log(response.data)
    setPatients(response.data.patients)
  }
  const[loading,setLoading]=useState(false)
  useEffect(()=>{
    getAllPatients()
  },[])
  

  let addTrip=async()=>{
    setLoading(true)
    const token=localStorage.getItem("token")
    try{
      if(patientRef=="Other Option" || patientRef=="Choose Patient By Name" || patientRef=="Choose Patient By Ref")
      {
        console.log("There Is No Patient Ref")
        setPatientRef("")
      }
      let response=await axios.post("https://api.triphog.net/api/v1/trip/addtrip",{patientName,patientPhoneNumber,patientRef:(patientRef=="Other Option" || patientRef=="Choose Patient By Name" || patientRef=="Choose Patient By Ref"?"":patientRef),patientType,pickUpDate,pickUpDate,pickUpTime,appointmentTime,legId,pickUpAddress,dropOffAddress},{headers:{'Authorization':token}})
      console.log(response.data)
   if(response.data.success){
    toast.success("Successfully Added Trip!",{position:"top-center"})
    setLoading(false)
    setTimeout(()=>{
      navigate("/admin/trips")
    },2000)
   }
   else{
    toast.error("There Was An Error While Adding Trip!",{position:"top-center"})
    setLoading(false)
   }
  }
  catch(e){
    setLoading(false)
    toast.error("Something Went While Adding Trip!")
  }
  }
  const[decodedUser,setDecodedUser]=useState({})
  useEffect(()=>{
    const token = localStorage.getItem("token");
      
      
      if (token) {
        try {
          const decoded = jwtDecode(token);
      
          // Check if the token is expired
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decoded.exp && decoded.exp > currentTime) {
            setDecodedUser(decoded)
          } else {
            console.log("Token has expired");
            navigate("/admin/login")
          }
        } catch (error) {
          navigate("/admin/login")
          console.log("Invalid token", error);
        }
      }
      
      if (decodedUser) {
        console.log("Decoded User:", decodedUser);
      } else {
        navigate("/admin/login")
        console.log("No valid token available, user not decoded.");
      }

  },[navigate])
 

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To Add Trip")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

 
  return (
    <>
      <div className="">
        <ToastContainer/>
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",margin:"1px"}}>BACK</h5>
          
          </button>
          <div class="w-full rounded-sm  bg-cover bg-center bg-no-repeat items-center">
            
            
          </div>
          <form
  className="text-darkgray laptop:text-lap_b text-[30px]"
  onSubmit={(event) => {
    event.preventDefault();
  }}
>
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
      <label>Patient Ref</label>
      <br />
      <select
        value={patientRef}
        onChange={(event) => {
          const selectedValue = event.target.value;
          if (selectedValue === "Other Option") {
            setIsOtherOptionSelected(true);
            setPatientRef(selectedValue);
            setPatientName("Other Option");
            setPatientPhoneNumber("");
          } else {
            const patientId = selectedValue;
            if (patientId !== "Choose Patient By Ref") {
              const _patients = patients.filter((patient) => patient._id === patientId);
              const Patient = _patients[0];
              setPatientName(Patient.firstName + " " + Patient.lastName);
              setPatientPhoneNumber(Patient.phoneNumber);
              setPatientRef(patientId);
              setIsOtherOptionSelected(false);
            }
          }
        }}
        className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
      >
        <option value="Choose Patient By Ref">Choose Patient By Ref</option>
        {patients.map((patient) => (
          <option key={patient._id} value={patient._id}>
            {patient._id}
          </option>
        ))}
        <option value="Other Option">Other Option</option>
      </select>
    </div>

    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
      <label>Patient Name (Full)</label>
      <br />
      {isOtherOptionSelected ? (
        <input
          type="text"
          placeholder="Enter Patient Name"
          
          onChange={(e) => setPatientName(e.target.value)}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        />
      ) : (
        <select
          value={patientName === "Other Option" ? "Other Option" : patientRef}
          onChange={(event) => {
            const patientId = event.target.value;
            if (patientId === "Other Option") {
              setPatientName("Other Option");
              setPatientRef("Other Option");
              setPatientPhoneNumber("");
              setIsOtherOptionSelected(true)
            } else if (patientId !== "Choose Patient By Name") {
              const _patients = patients.filter((patient) => patient._id === patientId);
              const Patient = _patients[0];
              setPatientName(Patient.firstName + " " + Patient.lastName);
              setPatientPhoneNumber(Patient.phoneNumber);
              setPatientRef(patientId);
            } else {
              setPatientRef("Choose Patient By Name");
            }
          }}
          className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
        >
          <option value="Choose Patient By Name">Choose Patient By Name</option>
          {patients.map((patient) => (
            <option key={patient._id} value={patient._id}>
              {patient.firstName + " " + patient.lastName}
            </option>
          ))}
          <option value="Other Option" selected={isOtherOptionSelected}>
            Other Option
          </option>
        </select>
      )}
    </div>
  </div>

  {/* Rest of the form continues unchanged */}
  <div className="laptop:flex gap-16 laptop:my-[16px]">
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
      <label>Patient Phone number</label>
      <br />
      <input
        type="Number"
        placeholder="546-933-2772"
        value={patientPhoneNumber}
        onChange={(event) => setPatientPhoneNumber(event.target.value)}
        className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
      />
    </div>
    <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
      <label className="">Patient Type</label>
      <br />
      <select
      value={patientType}
        onChange={(event) => setPatientType(event.target.value)}
        className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
      >
        <option disabled>Patient Type</option>
        <option  value="Wheel Chair">
          Wheel Chair
        </option>
        <option value="Normal">Normal</option>
      </select>
    </div>
  </div>
  <div className="flex items-center justify-between">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Leg ID</label>
                <br />
                <input
                  type="text"
                  placeholder="Q5469332171"
                   onChange={(event) => {
                     setLegId(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] mr-[45px] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
  <label>Appointment Time</label>
  <br />
  <input
    type="time"
    onChange={(event) => {
      setAppointmentTime(event.target.value);
    }}
    className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
  />
</div>

              
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">

<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
  <label>Pickup Date </label>
  <br />
  <input
    type="date"
    placeholder="12.02.2024"
     onChange={(event) => {
       setPickUpDate(event.target.value);
     }}
    className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
  />
</div>
<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Pick Up Time</label>
                <br />
                <input
                  type="time"
                   onChange={(event) => {
                     setPickUpTime(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
</div>

              <div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Pickup Address</label>
                <br />
              
                 <Autocomplete onLoad={onLoadPickup} onPlaceChanged={onPlaceChangedPickup}>
                 <input
                    type="text"
                    placeholder="H/No GH34"
                    onChange={(e)=>{
                      setPickUpAddress(e.target.value)
                    }}
                                      className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                 </Autocomplete>
                  
                 
                
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Drop off Address</label>
                <br />
                <Autocomplete onLoad={onLoadDropoff} onPlaceChanged={onPlaceChangedDropoff}>
                <input
                  type="text"
                  placeholder="Hospital"
                   onChange={(event) => {
                     setDropOffAddress(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                </Autocomplete>
              </div>
            </div>
            </div> 



             

            <div className="flex justify-center items-center">
              {loading?<button                 className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"><CircularProgress style={{color:"white",height:"18px",width:"18px"}} /></button>: <input
                onClick={(e) => {
                  e.preventDefault()
                  addTrip()

                  // setSubmit(true);//like they are going to use software.Never use this software.It was much better than this. N
                }}
                type="submit"
                className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"
              />}
              </div>

  {/* Further form sections */}
</form>

        </div>
      </div>
      <div className="mt-[-100px] bg-[white]">{/* <AdminSideBar /> */}</div>
      {/* {status && (
        <Modal status={status} setStatus={setStatus} message={message} />

      )} */}
    </>
  );
}
};


export default AddNewRide;

/*

*/
