import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useAllAdminsContext } from "../../../Providers/SuperAdmin/AllAdminsProvider";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Chip } from "@mui/material";
import { CiMenuKebab, CiSearch } from "react-icons/ci";
import { MdOutlineSort } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const Admins = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [query, setQuery] = useState("");
  const [sortAsc, setSortAsc] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [admins, setAdmins] = useState([]);

  const getAllAdmins = async () => {
    try {
      let response = await axios.get("https://api.triphog.net/api/v1/superadmin/admin");
      const sortedAdmins = response.data.data.sort((a, b) => a.companyName.localeCompare(b.companyName));
      setAdmins(sortedAdmins);
    } catch (e) {
      toast.error("Failed To Get Admins. Please Refresh The Page", { style: { fontFamily: "Cabin" } });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("superadmintoken");
            
                if (token) {
                  try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // Convert to seconds
            
                    if (decodedToken.exp && decodedToken.exp > currentTime) {
    getAllAdmins();
       
            
                      
                    } else {
                      console.log("Token has expired");
                      navigate("/superadmin/login");
                    }
                  } catch (error) {
                    console.log("Invalid token", error);
                    navigate("/superadmin/login");
                  }
                } else {
                  navigate("/superadmin/login");
                }
  }, []);

  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      if (selectedAdmin) {
        let response = await axios.delete(`https://api.triphog.net/api/v1/superadmin/admin/delete/${selectedAdmin._id}`);
        if (response.status === 200) {
          toast.success("Successfully Deleted!", { style: { fontFamily: "Cabin" } });
          setAdmins(admins.filter((admin) => admin._id !== selectedAdmin._id));
          setShowDeleteModal(false);
        } else {
          toast.error("Admin Could Not Be Deleted! Please Try Again", { style: { fontFamily: "Cabin" } });
          setShowDeleteModal(false);
        }
      }
    } catch (e) {
      toast.error("Admin Could Not Be Deleted! Please Try Again", { style: { fontFamily: "Cabin" } });
      setShowDeleteModal(false);
    } finally {
      setIsDeleting(false);
    }
  };

  const getAdminStatus = (createdAt, status, isOnHold) => {
    if (isOnHold) {
      return "On Hold";
    }
    const today = new Date();
    const createdDate = new Date(createdAt);
    const differenceInTime = today.getTime() - createdDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    if (differenceInDays <= 7 && status !== "Paid") {
      return "Pending";
    } else if (differenceInDays > 7 && status !== "Paid") {
      return "Failed";
    } else {
      return "Paid";
    }
  };

  const handleSort = () => {
    const sortedAdmins = [...admins].sort((a, b) =>
      sortAsc
        ? b.companyName.localeCompare(a.companyName)
        : a.companyName.localeCompare(b.companyName)
    );
    setAdmins(sortedAdmins);
    setSortAsc(!sortAsc);
  };

  const filteredAdmins = admins.filter(admin =>
    `${admin.firstName} ${admin.lastName}`.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <ToastContainer />
      <div className="flex w-full justify-between items-center">
        <h1 className="laptop:text-lap_c text-[40px] font-[600]">
          Subscribers / Admin
        </h1>
      </div>

      <div className="flex w-full justify-between items-center mt-[10px]">
        <Link
          to={"/superadmin/subscribers/addnewadmin"}
          className="float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] px-[26px] laptop:px-[14px] py-[15px] rounded-[10px] cursor-pointer"
        >
          Add New Admin
        </Link>
        <div className="float-right flex gap-[15px]">
          <input
            className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px]"
            type="text"
            placeholder="Search by Name"
            onChange={(event) => setQuery(event.target.value)}
          />
          <button
            onClick={handleSort}
            className="bg-[#f4f7ff] text-[#30325E] laptop:py-[8px] laptop:px-[14px] px-[26px] py-[15px] rounded-[10px] cursor-pointer text-[25px] laptop:text-lap_b border-[1.4px] border-[#30325E] border-solid"
          >
            <span className="flex items-center gap-3">
              Sort {sortAsc ? <MdOutlineSort className="rotate-0" /> : <MdOutlineSort className="rotate-180" />}
            </span>
          </button>
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-200 overflow-x-auto mt-[20px] border-[2px] border-solid border-[#B0B0B0] rounded-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Subscriber Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Subscription Plan
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Payment Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredAdmins.map((admin) => (
            <tr key={admin._id} className="cursor-pointer" onClick={() => {
              navigate(`/superadmin/subscribers/admin/${admin._id}`);
            }}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  <img className="h-10 w-10 rounded-full" src={admin.photo} alt="" />
                  <div className="ml-4 text-sm font-medium text-gray-900">
                    {`${admin.firstName} ${admin.lastName}`}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{admin.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {admin.plan}
              </td>
              <td className="py-4 px-6">
                <Chip label={getAdminStatus(admin.createdAt, admin.status, admin.isOnHold)} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap flex gap-3">
                <RiDeleteBin5Line
                  className="cursor-pointer text-red-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedAdmin(admin);
                    setShowDeleteModal(true);
                  }}
                />
                <FaPencil
                  className="cursor-pointer text-blue-600"
                  onClick={(e) => { e.stopPropagation(); navigate(`/superadmin/subscribers/updateadmin/${admin._id}`); }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteModal && selectedAdmin && (
        <Modal
          isOpen={showDeleteModal}
          onRequestClose={() => setShowDeleteModal(false)}
          title="Delete Confirmation"
          content={`Do you really want to delete ${selectedAdmin.firstName} ${selectedAdmin.lastName}?`}
          onConfirm={handleDelete}
          confirmLabel={isDeleting ? "Deleting..." : "Yes"}
          cancelLabel="Cancel"
        />
      )}
    </div>
  );
};

// Local Modal component
const Modal = ({ isOpen, onRequestClose, title, content, onConfirm, confirmLabel, cancelLabel }) => (
  isOpen && (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{content}</p>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
            <button type="button" onClick={onConfirm} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
              {confirmLabel}
            </button>
            <button type="button" onClick={onRequestClose} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 sm:mt-0 sm:w-auto sm:text-sm">
              {cancelLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
);

export default Admins;
