import axios from "axios";
import { jwtDecode } from "jwt-decode"; // No need to destructure it, jwt-decode is the default export.
import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const Drivers = () => {
  const navigate=useNavigate()
  
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const token = localStorage.getItem("token");

  // Only decode if token exists
  let decodedUser = null;
  if (token) {
    try {
      decodedUser = jwtDecode(token);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }

  // Fetch drivers from API
  const getAllDrivers = async () => {
    if (!token) return; // Prevent API call if there's no token

    setLoading(true); // Set loading to true before the API call
    try {
      let response = await axios.get(
        "https://api.triphog.net/api/v1/driver/getdrivers",
        { headers: { Authorization: token } }
      );
      console.log(response.data);
      setDrivers(response.data.drivers);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    } finally {
      setLoading(false); // Stop loading after the API call
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
        getAllDrivers()
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  }, [token]);

  // If there's no token, display a message
  if (!token) {
    return (
      <div className="flex flex-col justify-center items-center" style={{ fontFamily: "Cabin" }}>
        No token provided. Please log in.
      </div>
    );
  }

  // Access Denied Logic
  if (decodedUser?.role === "User" && !decodedUser?.accessibilities?.includes("Access To View Drivers")) {
    return (
      <div className="flex flex-col justify-center items-center" style={{ fontFamily: "Cabin" }}>
        Access Denied By The Admin!
      </div>
    );
  }

  return (
    <div className="bg-white rounded-[20px] p-[20px] mt-[20px] mr-[30px] shadow-lg drivers_table_parent overflow-x-auto">
      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
        </div>
      ) : (
         <table className="min-w-full divide-y divide-gray-300 mt-[20px] border-[2px] border-solid border-gray-300 rounded-lg overflow-hidden border-separate">

          <thead className="bg-gradient-to-r from-gray-100 to-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wide">
                Ref No
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wide">
                Driver Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wide">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {drivers.map((driver) => (
              <tr key={driver._id} className="hover:bg-gray-100 transition ease-in-out duration-200">
                <td className="px-6 py-4 break-words">
                  <div className="text-sm font-medium text-gray-900">{driver._id}</div>
                </td>
                <td className="px-6 py-4 break-words">
                  <h2 className="text-sm font-medium text-gray-900">{driver.firstName + " " + driver.lastName}</h2>
                </td>
                <td className="py-4 px-6 border-b border-gray-200">
                  <span
                    className={`${
                      driver.status === "Not Available"
                        ? "text-red-600 bg-red-100"
                        : driver.status === "Available"
                        ? "text-green-600 bg-green-100"
                        : "text-orange-500 bg-orange-100"
                    } flex items-center py-2 px-3 rounded-full text-xs font-medium justify-center`}
                  >
                    <GoDotFill className="mr-1" /> {driver.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Drivers;
