import React from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Box,CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const HomeSideBar = () => {
  const[adminStatistics,setAdminStatistics]=useState({})
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const navigate=useNavigate()
  const getUpcomingMeetings = async () => {
    try{
    let response = await axios.get("https://api.triphog.net/api/v1/meeting/getmeetings", {
      headers: { Authorization: localStorage.getItem("superadmintoken") },
    });

    const now = new Date();

    const upcomingMeetings = response.data.meetings.filter((meeting) => {
      const meetingDateTime = new Date(meeting.date);

      const [hours, minutes] = meeting.time.split(":").map(Number);
      meetingDateTime.setHours(hours, minutes, 0, 0);

      return meetingDateTime > now;
    });

    setUpcomingMeetings(upcomingMeetings);
  }
  catch(e){
    toast.error("Error While Getting Meetings!")
  }
  };
  let getAdminStatistics=async()=>{
    try{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/getadminstatistics")
    console.log("Admin Statistics")
    console.log(response.data)
    setAdminStatistics(response.data)
    }

    catch(e){
      toast.error("Error While Getting Admin Statistics")
    }
  }
  useEffect(()=>{
    const token = localStorage.getItem("superadmintoken");
            
                if (token) {
                  try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // Convert to seconds
            
                    if (decodedToken.exp && decodedToken.exp > currentTime) {
                      getAdminStatistics()
                      getUpcomingMeetings()
            
                      
                    } else {
                      console.log("Token has expired");
                      navigate("/superadmin/login");
                    }
                  } catch (error) {
                    console.log("Invalid token", error);
                    navigate("/superadmin/login");
                  }
                } else {
                  navigate("/superadmin/login");
                }
    
  },[])
  const roundValue = (num) => {
    return num % 1 >= 0.5 ? Math.ceil(num) : Math.floor(num);
  };
  if(Object.keys(adminStatistics).length==0)
    {
      return(<Box className="flex flex-col justify-center items-center mt-[150px]">
        <CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}} thickness={5} />
      </Box>)
    }
    else{
  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white ">
      <ToastContainer/>
      <div className="w-[100%] mt-[20px]">
        <div className="flex float-right text-lap_e cursor-pointer ">
          <IoNotificationsOutline />
        </div>
      </div>
      <div className="w-[100%] mt-[-10px]">
        <h2 className="text-lap_c mt-[60px] font-[600]">Subscribers</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{adminStatistics.totalAdmins}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{roundValue(adminStatistics.paidPercentage)}%</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Paid</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{roundValue(adminStatistics.pendingPercentage)}%</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Pending</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{roundValue(adminStatistics.failedPercentage)}%</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Failed</span>
            </div>
          </div>
          <div>Chart</div>
        </div>
        <h2 className="text-lap_c font-[700] mt-[20px] mb-[15px]">
          Today, Meetings
        </h2>
        <div className="space-y-4 max-h-[300px] overflow-y-auto">
          {upcomingMeetings.length > 0 ? (
            upcomingMeetings.map((meeting) => (
              <div
                key={meeting._id}
                className="border p-4 rounded-lg shadow-lg bg-white"
              >
                <h3 className="font-bold text-sm mb-2">{meeting.title}</h3>
                
                <div className="mb-2">
                  <p className="text-sm">
                    <strong>Date:</strong> {new Date(meeting.date).toLocaleDateString()}
                  </p>
                  <p className="text-sm">
                    <strong>Time:</strong> {meeting.time}
                  </p>
                </div>

                <div className="mb-2">
                  <p className="text-sm">
                    <strong>Location:</strong> {meeting.location}
                  </p>
                  <p className="text-sm">
                    <strong>With:</strong> {meeting.scheduleWith}
                  </p>
                </div>

                <p className="text-sm">
                  <strong>Notes:</strong> {meeting.notes}
                </p>
              </div>
            ))
          ) : (
            <p className="text-lap_e">No upcoming meetings for today.</p>
          )}
        </div>
       </div>
    </div>
  );
}
};

export default HomeSideBar;
