import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
function TripLogs()
{
    let navigate=useNavigate()
    const[driversArray,setDriversArray]=useState([])
    const[filteredTrips,setFilteredTrips]=useState([])
    
    const[logDateRange,setLogDateRange]=useState([{ startDate: new Date(),
        endDate: new Date(),
        key: 'selection'}])
    const [trips,setTrips]=useState([])
    const token=localStorage.getItem("token")
    
       
       let decodedUser = {};
       
       if (token) {
         try {
           const decoded = jwtDecode(token);
       
           // Check if the token is expired
           const currentTime = Date.now() / 1000; // Convert to seconds
           if (decoded.exp && decoded.exp > currentTime) {
             decodedUser = decoded;
           } else {
             console.log("Token has expired");
             navigate("/admin/login")
           }
         } catch (error) {
           navigate("/admin/login")
           console.log("Invalid token", error);
         }
       }
       
       if (decodedUser) {
         console.log("Decoded User:", decodedUser);
       } else {
         navigate("/admin/login")
         console.log("No valid token available, user not decoded.");
       }

    let checkPermission=(permission)=>{
      console.log("Decoded User Permissions",decodedUser)
      if(decodedUser.role=="Admin")
      {
        return false
      }
      else{
        return (decodedUser.accessibilities.includes(permission))
      }
      
  
    }
    const[loading,setLoading]=useState(false)
    let getTrips=async()=>{
        setLoading(true)
        const token=localStorage.getItem("token")
        let response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
        setTrips(response.data.trips)
        setLoading(false)
    }
    useEffect(()=>{
         const token = localStorage.getItem("token");
            
                if (token) {
                  try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // Convert to seconds
            
                    if (decodedToken.exp && decodedToken.exp > currentTime) {
            getTrips()
                      
                    } else {
                      console.log("Token has expired");
                      navigate("/admin/login");
                    }
                  } catch (error) {
                    console.log("Invalid token", error);
                    navigate("/admin/login");
                  }
                } else {
                  navigate("/admin/login");
                }

    },[])
    const handleGoBack = () => {
      navigate(-1); // Navigate to the previous page
    };
    const createPrintWindow = () => {
      let printContent = '';
      console.log("Drivers Array", driversArray);
  
      driversArray.forEach((driver, index) => {
          let transportationProvider = 'Random Transportation Provider'; // Set specific for each driver
          let vehicleId = 'triphog33'; // Set specific for each driver
  
          const { driverName, trips } = driver;
          console.log("Driver Signature Url", driver.signatureUrl);
          const driverSignatureUrl = driver.signatureUrl || '';
          const patientSignatureUrl = driver.patientSignatureUrl || '';
  
          const tripsContent = trips.map((trip, tripIndex) => `
              <tr>
                  <td>${tripIndex + 1}</td>
                  <td>${trip.pickUpTime || ''}</td>
                  <td>${trip.legId || ''}</td>
                  <td>${trip.patientName || ''}</td>
                  <td>${trip.comments || ''}</td>
                  <td>${trip.completedAt ? new Date(trip.completedAt).toDateString() + " " + new Date(trip.completedAt).toLocaleTimeString() : ' '}</td>
                  <td>${trip.status == "Cancelled" || trip.status == "No Show" ? "Yes" : 'No'}</td>
                  <td><img src="${trip.patientSignatureUrl}" alt="Patient Signature" width="100" /></td>
              </tr>
          `).join('');
  
          printContent += `
              <div class="driver-section">
                  <div class="header">
                      <div class="box">
                          <strong>Transportation Provider:</strong>
                          <input type="text" id="transportationProvider${index}" value="${transportationProvider}" oninput="updateProvider(${index}, this.value)">
                      </div>
                      <div class="box">
                          <strong>Date Range:</strong> ${new Date(logDateRange[0].startDate).toDateString() + " " + new Date(logDateRange[0].endDate).toDateString()}
                      </div>
                      <div class="box">
                          <strong>Driver Name:</strong> ${driverName}
                      </div>
                      <div class="box">
                          <strong>Vehicle ID:</strong>
                          <input type="text" id="vehicleId${index}" value="${vehicleId}" oninput="updateVehicleId(${index}, this.value)">
                      </div>
                  </div>
                  <table>
                      <thead>
                          <tr>
                              <th>#</th>
                              <th>Pick Up Time</th>
                              <th>Leg ID</th>
                              <th>Patient Name</th>
                              <th>Comments</th>
                              <th>Drop Off Time</th>
                              <th>Cancel/No Show</th>
                              <th>Patient Signature</th>
                          </tr>
                      </thead>
                      <tbody>
                          ${tripsContent}
                      </tbody>
                  </table>
                  <div class="footer">
                      <p>This is to certify that the foregoing information is true, accurate, and complete. I know that payment of this claim will be from Federal and State Funds and that any falsification or concealment of any material fact may be prosecuted under Federal and State Laws.</p>
                      <div class="signature-box">
                          <p><strong>Driver Signature:</strong></p>
                          ${driverSignatureUrl ? `<img src="${driverSignatureUrl}" alt="Driver Signature" />` : `<p>No Signature</p>`}
                      </div>
                  </div>
                  <button onclick="window.print()">Download All Logs</button>
              </div>
          `;
      });
  
      const printWindow = window.open('', '', 'width=800,height=600');
      if (!printWindow) {
          console.error('Failed to open print window.');
          return;
      }
  
      printWindow.document.open();
      printWindow.document.write(`
          <html>
          <head>
              <title>Logs</title>
              <style>
                  body {
                      font-family: Arial, sans-serif;
                      margin: 20px;
                      border: 5px solid black; /* Black border around the whole window */
                      overflow-y: scroll; /* Enable scrolling */
                  }
                  .driver-section {
                      margin-bottom: 20px;
                      border: 2px solid #000; /* Border around each driver section */
                      padding: 10px;
                  }
                  table {
                      width: 100%;
                      border-collapse: collapse;
                  }
                  th, td {
                      border: 1px solid #ccc;
                      padding: 2px; /* Minimal padding for height reduction */
                      text-align: left;
                      font-size: 12px; /* Reduced font size */
                      height: 13px; /* Minimum height */
                  }
                  th {
                      background-color: #f4f4f4;
                  }
                  img {
                      max-width: 100px;
                      max-height: 100px;
                      display: block;
                  }
                  .header {
                      display: grid;
                      grid-template-columns: 1fr 1fr; /* Two columns */
                      grid-gap: 10px;
                      margin-bottom: 20px;
                  }
                  .header .box {
                      border: 1px solid #000; /* Border around each box */
                      padding: 5px;
                      width: 100%; /* Full width of grid cell */
                  }
                  .footer {
                      margin-top: 20px;
                      text-align: center;
                  }
                  .footer .signature-box {
                      border: 2px solid #000;
                      padding: 10px;
                      width: 300px;
                      display: inline-block;
                      margin: 0 auto;
                  }
              </style>
          </head>
          <body>
              ${printContent}
              <script>
                  function updateProvider(index, value) {
                      document.querySelectorAll('#transportationProvider' + index).forEach(input => input.value = value);
                  }
                  function updateVehicleId(index, value) {
                      document.querySelectorAll('#vehicleId' + index).forEach(input => input.value = value);
                  }
                  function downloadDriver(index) {
                      const sections = document.querySelectorAll('.driver-section');
                      const section = sections[index];
  
                      // Create a new window for printing
                      const printWindow = window.open('', '', 'width=800,height=600');
  
                      // Add the content of the specific section to the new window
                      printWindow.document.write('<html><head><title>Driver Logs</title>');
                      printWindow.document.write('</head><body>');
                      printWindow.document.write('<h1>Driver Logs</h1>');
                      printWindow.document.write(section.outerHTML); // Get the HTML of the driver section
                      printWindow.document.write('</body></html>');
  
                      // Close the document to finish writing
                      printWindow.document.close();
  
                      // Wait for the content to fully load, then trigger the print dialog
                      printWindow.focus();
                      printWindow.print();
  
                      // Close the print window after printing
                      printWindow.onafterprint = function() {
                          printWindow.close();
                      };
                  }
              </script>
          </body>
          </html>
      `);
      printWindow.document.close();
  };
  
  if((decodedUser.role=="User") && (checkPermission("Access To Download Logs")==false))
  {
    return(
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">
            Unauthorized
          </h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the
            administrator or go back.
          </p>
          <button
            onClick={()=>{
              navigate(-1)
            }}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    )
  }
  else{
  
return(  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
      height: "100vh", // Full viewport height for centering
      width: "100%", // Full width
      padding: "0 20px", // Padding for responsiveness
      boxSizing: "border-box"
    }}
  >
    
    {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CircularProgress size={60} thickness={5} color="inherit" />
        </div>
      )}
    <div style={{ width: "100%", maxWidth: "800px" }}>
      <DateRangePicker
        onChange={(item) => {
            console.log("Selected Date Range")
            console.log(item.selection)
            let startDate=new Date(item.selection.startDate)
            let endDate=new Date(item.selection.endDate)
          console.log("Start Date",startDate)
          console.log("End Date",endDate)
            let filteredTrips=trips.filter((trip)=>{
              // console.log(trip.createdAt.getTime()>=startDate.getTime())
              // console.log(trip.createdAt.getTime()<=endDate.getTime())
              // console.log("Created AT",trip.createdAt)
              let tripCreatedAt=new Date(trip.createdAt)
              return(tripCreatedAt.getTime()>=startDate.getTime() && tripCreatedAt.getTime()<=endDate.getTime())
            })
            console.log("Selected Date Range Trips",filteredTrips)
            let driversForLogs=[]
            let driversWithAssignedTrips=[]
            for(let trip of filteredTrips)
            {
              if(trip.driverRef.length>0)
              {
                let alreadyAdded=false
               for(let driver of driversWithAssignedTrips)
               {
                if(trip.driverRef==driver.ref)
                {
                  alreadyAdded=true

                }
               }
               if(!alreadyAdded)
               {
                console.log(trip.driverSignatureUrl)
                driversWithAssignedTrips.push({ref:trip.driverRef,name:trip.driverName,signatureUrl:trip.driverSignatureUrl})


               }
              }
            }
            console.log("Drivers With Assigned Trips",driversWithAssignedTrips)
            for(let driver of driversWithAssignedTrips)
            {
              let driverTrips=filteredTrips.filter((trip)=>{return(trip.driverRef==driver.ref)})
              console.log("Driver Trips",driverTrips)
              driversForLogs.push({driverName:driver.name,trips:driverTrips,signatureUrl:driver.signatureUrl})
        
            }
            console.log("Driver For Logs")
            console.log(driversForLogs)
            setDriversArray(driversForLogs)
            setFilteredTrips(filteredTrips)
            setLogDateRange([item.selection])
        }}
        ranges={logDateRange}
        direction="horizontal"
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        editableDateInputs={true}
        months={2}
        rangeColors={["#30325E"]} // Deep blue color for selected range
      />
    </div>
    <button
      disabled={
        decodedUser.role === "User" &&
        !checkPermission("Access To Download Logs")
      }
      style={{
        marginTop: "20px",
        padding: "10px 20px",
        backgroundColor: "#30325E", // Deep blue button color
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={() => {
        createPrintWindow();
      }}
    >
      Download PDF
    </button>
  </div>
  
          )
        }

}
export default TripLogs
//font-family: "Cabin", "sans-serif" !important;