import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Wellcome = () => {
  const navigate=useNavigate()
  const token=localStorage.getItem("token")
  let decodedUser = {};
   
   if (token) {
     try {
       const decoded = jwtDecode(token);
   
       // Check if the token is expired
       const currentTime = Date.now() / 1000; // Convert to seconds
       if (decoded.exp && decoded.exp > currentTime) {
         decodedUser = decoded;
       } else {
         console.log("Token has expired");
         navigate("/admin/login")
       }
     } catch (error) {
       navigate("/admin/login")
       console.log("Invalid token", error);
     }
   }
   
   if (decodedUser) {
     console.log("Decoded User:", decodedUser);
   } else {
     navigate("/admin/login")
     console.log("No valid token available, user not decoded.");
   }
  const[admin,setAdmin]=useState({})
  let getAdminById=async()=>{
    try{
    let response=await axios.get(`https://api.triphog.net/api/v1/superadmin/admin/getById/${localStorage.getItem("token")}`)
 console.log("Admin Data",response.data)
 setAdmin(response.data.data)
    setAdmin(response.data.data)
    }
    catch(e){
      toast.error("Error While Getting Admin!Please Try Again Or Refresh The Page")
    }
  }
  useEffect(()=>{
    if(!token){
      navigate("/admin/login")
    }
    else{
    getAdminById()

    }
  },[])
  return (
    <div className="laptop:grid laptop:grid-cols-[8%_auto] laptop:items-center hidden bg-[#F4F7FF] laptop:px-[30px] laptop:pt-[8px]">
      <div className="bg-[#C8F7DC] h-[60px] w-[60px] rounded-full">
        <ToastContainer/>
        <img
          className="h-full w-full rounded-full"
         src={decodedUser.role=="User"?decodedUser.profilePhotoUrl:admin.photo}
          alt="profile"
        />
      </div>
      <div>
        <h1 className="text-[16px] text-[#262626]">
          <b>Welcome Back</b>, {decodedUser.role=="User"?decodedUser.fullName:admin.firstName+" "+admin.lastName}👋{" "}
        </h1>
        <h3 className="text-[10px] text-[#26262657] font-[400]">
          Monthly Ultimate User
        </h3>
      </div>
    </div>
  );
};

export default Wellcome;
