import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { jwtDecode } from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";
import { useSocket } from "../../SocketContext/SocketContext";
import CircularProgress from "@mui/material/CircularProgress"; // Import MUI Spinner

const Wellcome = () => {
  const navigate = useNavigate();
  const token=localStorage.getItem("token")
  const jwt_Token = localStorage.getItem("token");
 
 let decodedUser = {};
 
 if (jwt_Token) {
   try {
     const decoded = jwtDecode(jwt_Token);
 
     // Check if the token is expired
     const currentTime = Date.now() / 1000; // Convert to seconds
     if (decoded.exp && decoded.exp > currentTime) {
       decodedUser = decoded;
     } else {
       console.log("Token has expired");
       navigate("/admin/login")
     }
   } catch (error) {
     navigate("/admin/login")
     console.log("Invalid token", error);
   }
 }
 
 if (decodedUser) {
   console.log("Decoded User:", decodedUser);
 } else {
   navigate("/admin/login")
   console.log("No valid token available, user not decoded.");
 }
  const [admin, setAdmin] = useState({});
  const [unreadMessages, setUnreadMessages] = useState(0); // State for unread messages
  const [loading, setLoading] = useState(false); // Loading state for message fetching
  const socket = useSocket(); // Use socket context

  // Function to get admin data
  let getAdminById = async () => {
    let decodedUser = jwtDecode(localStorage.getItem("token"));
    console.log("Decoded User", decodedUser);
    if (decodedUser.role === "Admin") {
      let response = await axios.get(
        `https://api.triphog.net/api/v1/superadmin/admin/getById/${localStorage.getItem(
          "token"
        )}`
      );
      console.log("Admin Data", response.data);
      setAdmin(response.data.data);
    } else if (decodedUser.role === "User") {
      let response = await axios.get(
        `https://api.triphog.net/api/v1/superadmin/admin/getById/${localStorage.getItem(
          "token"
        )}`
      );
      console.log("Admin Data Using Decoded User", response.data);
      setAdmin(response.data.data);
    }
  };

  // Function to get latest messages and count unread messages
  let getLatestMessages = async () => {
    try {
      setLoading(true); // Start loading when fetching messages
      console.log("Getting Unread Msgs");
      let userId =
        decodedUser.role === "Admin" ? decodedUser.id : decodedUser.createdBy;
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.triphog.net/api/v1/chat/getmymessages",
        {
          headers: { Authorization: token },
        }
      );
      const allMessages = response.data.allTimeMessages.filter((message) => {
        return message.senderId !== userId;
      });

      const unreadMessages = allMessages.filter((msg) => !msg.isRead);
      setUnreadMessages(unreadMessages.length); // Set unread message count
      setLoading(false); // Stop loading once messages are fetched
    } catch (error) {
      console.log("Error fetching messages", error);
      setLoading(false); // Stop loading on error
    }
  };

  // Effect to fetch admin data and messages on component mount
  useEffect(() => {
    if(!token)
    {
      navigate("/admin/login")

    }
    else{
      getAdminById();
      getLatestMessages();
  
    }
    
    // Listen to socket events for new messages
    if (socket) {
      socket.on("new-notification", (message) => {
        console.log("New Notification Msg Received");
        getLatestMessages(); // Recall when new notification is received
      });
      socket.on("reload-notifications", () => {
        console.log("Reloading Notifications");
        getLatestMessages();
      });
    }
  }, [socket]);

  return (
    <div className="flex items-center justify-between bg-[#F4F7FF] pr-[30px] py-4">
      <div className="laptop:grid laptop:grid-cols-[20%_auto] gap-5 laptop:items-center hidden bg-[#F4F7FF] laptop:px-[30px] laptop:pt-[8px]">
        <div className="bg-[#C8F7DC] h-[60px] w-[60px] rounded-full overflow-hidden shadow-md">
          <img
            className="h-full w-full object-cover"
            src={
              decodedUser.role === "Admin"
                ? admin.photo
                : decodedUser.profilePhotoUrl
            }
            alt="profile"
          />
        </div>
        <div>
          <h1 className="text-[16px] text-[#262626] font-semibold">
            <b>Welcome Back</b>,{" "}
            {decodedUser.role === "Admin"
              ? `${admin.firstName} ${admin.lastName}`
              : decodedUser.fullName}{" "}
            👋
          </h1>
          <h3 className="text-[10px] text-[#26262657] font-[400]">
            Monthly Ultimate User
          </h3>
        </div>
      </div>

      <div className="flex items-center gap-[40px] meeting_top">
        <div className="flex text-[#959595] items-center">
          <span className="text-lap_c font-[400]">Company Code: &nbsp;</span>
          <span className="text-lap_c font-[700] text-black">
            {admin.companyCode}
          </span>
        </div>

        {/* Notification Icon with Loading Spinner */}
        <div
          onClick={() => {
            navigate("/admin/notifications");
          }}
          className={`relative flex cursor-pointer text-lap_e ${
            unreadMessages > 0 ? "text-deepBlue" : "text-white"
          } hover:text-blue-500 transition duration-200`}
        >
          {/* Show loading spinner if loading is true */}
          {loading ? (
            <CircularProgress size={24} style={{ color: "#30325E" }} />
          ) : (
            <IoNotificationsOutline
              style={{ color: "#30325E" }}
              className="text-2xl"
            />
          )}

          {/* Display number of unread messages as a badge */}
          {unreadMessages > 0 && !loading && (
            <span className="absolute top-[-8px] right-[-8px] h-[24px] w-[24px] rounded-full bg-gray-500 text-white text-xs flex items-center justify-center">
              {unreadMessages > 10 ? "10+" : unreadMessages}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wellcome;
