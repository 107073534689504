import { IoArrowBackOutline } from "react-icons/io5";
import { MdLinkedCamera } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress,MenuItem,FormControl,InputLabel,Select } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Index = () => {
  let navigate = useNavigate()  
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [EMailAddress, setEMailAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [location, setLocation] = useState("")
  const [gender, setGender] = useState("")
  const [vehicleName, setVehicleName] = useState("")
  const [hourlyPay, setHourlyPay] = useState(0)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('success');
  const [message, setMessage] = useState('');
  const[payPerMile,setPayPerMile]=useState(0)
  // New state variables for file uploads
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [signature, setSignature] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [license, setLicense] = useState(null);
  const[profilePhotoUrl,setProfilePhotoUrl]=useState("")
  const[signatureUrl,setSignatureUrl]=useState("")
  const[IdCardUrl,setIdCardUrl]=useState("")
  const[licenseUrl,setLicenseUrl]=useState("")
  const[paymentType,setPaymentType]=useState("hourly")
  const handlePaymentTypeChange=(e)=>{
    setPaymentType(e.target.value)
  }
  const closeModal = () => {
    setOpen(false);
    navigate("/admin/drivers")
  };  

  const handleFileUpload = (event, setterFunction) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setterFunction(file);
      };
      reader.readAsDataURL(file);
    }
  };

  let addNewDriver = async () => {
    setLoading(true);
  
    // Checking if any required fields are missing
    if (!firstName || !lastName || !EMailAddress || !phoneNumber || !location || !gender || !vehicleName) {
      toast.warn("Please fill in all the required fields!");
      setLoading(false);
      return;
    }
  
    // Checking if any required files are missing
   
    if (!signature) {
      toast.warn("Please upload a signature!");
      setLoading(false);
      return;
    }
   
    if (!license) {
      toast.warn("Please upload a driver's license!");
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("EMailAddress", EMailAddress);
    formData.append("phoneNumber", phoneNumber);
    formData.append("location", location);
    formData.append("gender", gender);
    formData.append("vehicleName", vehicleName);
    formData.append("hourlyPay", hourlyPay);
    formData.append("signature", signature);
    formData.append("paymentType",paymentType)
    formData.append("payPerMile",payPerMile)
    if(profilePhoto)
    {
    formData.append("profilePhoto", profilePhoto);
    }
    if(idCard)
    {
    formData.append("IDCard", idCard);


    }
    formData.append("liscense", license);
  
    const token = localStorage.getItem("token");
  
    try {
      let response = await axios.post(
        "https://api.triphog.net/api/v1/driver/addnewdriver",
        formData,
        { headers: { "Authorization": token } }
      );
  console.log("Response",response.data)
      if (response.data.success) {
        toast.success("Driver Added Successfully");
      } else {
        toast.error("There was an error while adding the driver!");
      }
    } catch (error) {
      console.log(error)
      toast.error("Failed to add driver. Please try again!");
    } finally {
      setLoading(false);
    }
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  const[decodedUser,setDecodedUser]=useState({});
  useEffect(()=>{
    const token = localStorage.getItem("token");
      
      
      if (token) {
        try {
          const decoded = jwtDecode(token);
      
          // Check if the token is expired
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decoded.exp && decoded.exp > currentTime) {
            setDecodedUser(decoded)
          } else {
            console.log("Token has expired");
            navigate("/admin/login")
          }
        } catch (error) {
          navigate("/admin/login")
          console.log("Invalid token", error);
        }
      }
      
      if (decodedUser) {
        console.log("Decoded User:", decodedUser);
      } else {
        navigate("/admin/login")
        console.log("No valid token available, user not decoded.");
      }

  },[navigate])
 

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  if(decodedUser.role=="User" &&(!checkPermission("Access To Add Driver")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );

  }
else {
  return (
    <>
      <div className="laptop:h-screen">
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
          <button style={{display:"flex",flexDirection:"row"}} onClick={() => navigate(-1)}>
            <img style={{height:"26px",width:"23px",marginRight:"10px"}} src={require('./left-arrow.png')}/>
            <h5 style={{marginRight:"15px",color:"black",textTransform:"capitalize",fontWeight:"bold",marginTop:"1px"}}>Add New Driver</h5>
          </button>
          
          <ToastContainer/>
          
          <div className="flex justify-center mb-6">
            <div className="relative">
              <img
                src={profilePhoto!=null?URL.createObjectURL(profilePhoto):  "default-avatar.png"}
                
                className="w-24 h-24 rounded-full object-cover"
              />
              <label htmlFor="profile-upload" className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-2 cursor-pointer">
                <MdLinkedCamera className="text-white" />
              </label>
              <input
                id="profile-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={(e) => {setProfilePhoto(e.target.files[0])}}
              />
            </div>
           
  
          </div>

          <form className="text-darkgray laptop:text-lap_b text-[30px]" onSubmit={(event) => event.preventDefault()}>
     
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">First Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your First Name"
                  onChange={(event) => setFirstName(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter your Last Name"
                  onChange={(event) => setLastName(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Your Email</label>
                <br />
                <input
                  type="text"
                  placeholder="jaskolski.brent@gmail.com"
                  onChange={(event) => setEMailAddress(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  placeholder="546-933-2772"
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
            {
              paymentType=="hourly"? <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
              <label className="">Hourly Pay</label>
              <br />
              <input
                type="number"
                placeholder="Pay Per Hour"
                onChange={(event) => setHourlyPay(event.target.value)}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              /> 
            </div>:paymentType=="mileage"? <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Pay Per Mile</label>
                <br />
                <input
                  type="number"
                  placeholder="Pay Per Mile"
                  onChange={(event) => setPayPerMile(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                /> 
              </div>:<></>
            } 
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Location</label>
                <br />
                <input
                  type="text"
                  placeholder="New York"
                  onChange={(event) => setLocation(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Vehicle Name</label>
                <br />
                <input
                  type="text"
                  placeholder="Toyota Prius"
                  onChange={(event) => setVehicleName(event.target.value)}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Gender</label>
                <br />
                <select
                  onChange={(event) => setGender(event.target.value)}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option selected>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
            </div>
            
            {/* New fields for file uploads */}
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Signature Upload</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>{setSignature(e.target.files[0])}}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {signature && <img src={URL.createObjectURL(signature)} alt="Signature" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
              {/* <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>ID Card Upload</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {setIdCard(e.target.files[0])}}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {idCard && <img src={URL.createObjectURL(idCard)} alt="ID Card" className="mt-2 max-w-full h-20 object-contain" />}
              </div> */}
               <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>License Upload</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {setLicense(e.target.files[0])}}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {license && <img src={URL.createObjectURL(license)} alt="License" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
             
              <div className="laptop:w-[45%] mt-[15px] h-[10px] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <FormControl sx={{ width: "100%", height: "12px" }}>
  <InputLabel
    sx={{
      fontFamily: "Cabin",
      fontSize: "14px", // Adjust font size to fit height
      color: "#30325E !important",
      '&.Mui-focused': { color: "#30325E !important" },
      '&:hover': { color: "#30325E !important" }
    }}
  >
    Payment Type
  </InputLabel>

  <Select
    value={paymentType}
    label="Payment Type"
    onChange={handlePaymentTypeChange}
    sx={{
      fontFamily: "Cabin",
      fontSize: "14px", // Adjust font size for smaller height
      lineHeight: "50px", // Align text within the height
      height: "50px", // Set overall height of Select
      marginTop:"10px",
      backgroundColor: "#F4F7FF",
      borderRadius: "8px",
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
    }}
  >
    <MenuItem value="hourly" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Hourly Pay</MenuItem>
    <MenuItem value="mileage" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Pay by Mileage</MenuItem>
    <MenuItem value="direct" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Direct Pay</MenuItem>
  </Select>
</FormControl>

</div>
            </div>

            <div className="flex justify-center items-center mt-[85px]">
              {loading ? 
                <button disabled  className="bg-deepBlue w-[300px] m-auto rounded-xl  laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white">
                <CircularProgress style={{height:"20px",width:"20px",color:"white"}} />

                </button>
               : 
                <button 
                  variant="contained" 
                  style={{borderRadius:"10px",fontSize:"13px", width:"210px",height:"38px",backgroundColor:"darkblue",color:"white"}} 
                  className="bg-deepblue w-[300px] m-auto rounded-xl text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white" 
                  onClick={addNewDriver}
                >
                  Save
                </button>
              }
            </div>
          </form>
        </div>
      </div>
      
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
            <div className="flex flex-col items-center">
              {type === 'success' ? (
                <svg className="w-12 h-12 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              ) : (
                <svg className="w-12 h-12 text-red-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              )}
              <h3 className="text-lg font-semibold mb-2">
                {type === 'success' ? 'Success' : 'Error'}
              </h3>
              <p className="text-center mb-4">{message}</p>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
};

export default Index;