import React, { useEffect, useState } from "react";

// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import DriverprofileHeader from "./DriverprofileHeader";
import SingleDriverSideBar from "./SingleDriverSideBar";
import Modal from "../../../Modal";
import Rides from "./Rides";
import { MdLinkedCamera, MdOutlineCancel } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SentimentDissatisfiedRounded } from "@mui/icons-material";
import { Button, CircularProgress,Select,FormControl,InputLabel,Menu,MenuItem } from "@mui/material";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { ButtonGroup,Box } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useSyncExternalStore } from "react";

const EditDriver = () => {
  const[paymentType,setPaymentType]=useState("hourly")
  const[noOfTotalTrips,setNoOfTotalTrips]=useState(0)
  const[noOfOnGoingTrips,setNoOfOnGoingTrips]=useState(0)
  const[noOfCompletedTrips,setNoOfCompletedTrips]=useState(0)
  const[noOfCancelledTrips,setNofOfCancelledTrips]=useState(0)
    const[firstName,setFirstName]=useState("")
    const[lastName,setLastName]=useState("")
    const[EMailAddress,setEMailAddress]=useState("")
    const[phoneNumber,setPhoneNumber]=useState("")
    const[location,setLocation]=useState("")
    const[gender,setGender]=useState("")
    const[vehicleName,setVehicleName]=useState("")
    const[hourlyPay,setHourlyPay]=useState(0)
    const[signatureUrl,setSignatureUrl]=useState("")
    const[profilePhotoUrl,setProfilePhotoUrl]=useState("")
    const[licenseUrl,setLicenseUrl]=useState("")
    const[IDCardUrl,setIDCardUrl]=useState("")
    const[driverId,setDriverId]=useState("")
    const[IDCard,setIDCard]=useState(null)
    const[liscense,setLiscense]=useState(null)
    const[signature,setSignature]=useState(null)
    const[isApproved,setIsApproved]=useState(false)
    const[payPerMile,setPayPerMile]=useState(0)
  const{id}=useParams()
  let navigate=useNavigate()
  const[successfullyFetched,setSuccessfullyFetched]=useState(false)
  const[loading,setLoading]=useState(false)
  const[last24HoursRidden,setLast24HoursRidden]=useState(0)
  const[last7DaysRidden,setLast7DaysRidden]=useState(0)
  const[last30DaysRidden,setLast30DaysRidden]=useState(0)
  const[allTimeRidden,setAllTimeRidden]=useState(0)
  const now=new Date()
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const[isLoading,setIsLoading]=useState(false)
  const [selectedDocument, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [documents, setDocuments] = useState([]);
  const[docs,setDocs]=useState([])
  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setDocument(file);
      setDocumentName(file.name);
    }
  };

  // Function to handle saving the document
  const saveDocument = async() => {
    if (document) {
      setLoading(true)
      // Call your API to save the document
      // For demonstration, we'll just add it to the state
      try{
        const formData=new FormData()
        formData.append("document",selectedDocument)
        console.log("Driver Id",driverId)
        let response=await axios.post(`https://api.triphog.net/api/v1/driver/adddoc/${driverId}`,formData,{headers:{"Content-Type":"multipart/form-data"}})
        console.log("API response",response.data)
        if(response.data.success)
        {
          setDocs([...docs,response.data.doc])
          // setDocuments([...documents, document]);
          setDocument(null); // Clear selected document
          setDocumentName(''); // Clear document name
          toast.success("Successfully Uploaded Document!")

        }
        else{
          toast.error("Sorry Document Could Not Be Uploaded!Please Try Again")
        }
      }
      catch(e){
        toast.error("Sorry Document Could Not Be Uploaded!Please Try Again"+e.message)


      }
      finally{
        setLoading(false)
      }
     
      // Reset any other state as needed
    }
  };

  // Function to handle canceling the document selection
  const cancelDocument = () => {
    setDocument(null);
    setDocumentName('');
  };
  const deleteDoc=async(docId)=>{
    setLoading(true)
    try{
      let response=await axios.delete(`https://api.triphog.net/api/v1/driver/deletedoc/${driverId}/${docId}`)
      if(response.data.success)
      {
        toast.success("Successfully Deleted Document")
        setDocs(docs.filter((doc)=>{ return(doc.Id!=docId)}))
      
      }
      else{
        toast.error("Sorry Document Could Not Be Deleted!Please Try Again")
      }
    }
    catch(e){
      toast.error("Sorry Document Could Not Be Deleted!Please Try Again")



    }
    finally{
      setLoading(false)
    }
  }

  let updateDriver=async()=>{
  setIsLoading(true)
  try{
  const token=localStorage.getItem("token")
  const formData=new FormData()
  formData.append("firstName",firstName)
  formData.append("lastName",lastName)
  formData.append("phoneNumber",phoneNumber)
  formData.append("location",location)
  formData.append("hourlyPay",hourlyPay)
  formData.append("vehicleName",vehicleName)
  formData.append("gender",gender)
  formData.append("profilePhoto",profilePhoto)
  formData.append("IDCard",IDCard)
  formData.append("liscense",liscense)
  formData.append("paymentType",paymentType)
  formData.append("payPerMile",payPerMile)
  formData.append("signature",signature)
  console.log("Profile Photo",profilePhoto)
    let response=await axios.put(`https://api.triphog.net/api/v1/driver/update/${id}`,formData,{headers:{'Authorization':token,"Content-Type":"multipart/form-data"}})
    console.log(response.data)
    if(response.data.success)
    {
      toast.success("Successfully Updated!",{position:"top-center",style:{fontFamily:"Cabin",fontWeight:"bold"}})
      setIsLoading(false)
    }
    else{
      toast.error("Error While Updating Driver!",{position:"top-center",style:{fontFamily:"Cabin",fontWeight:"bold"}})
      setIsLoading(false)
    }
  }
  catch(e){
    setIsLoading(false)
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
  }
  }
  const[profilePhoto,setProfilePhoto]=useState(null)
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    console.log("File To Set",file)
    if (file) {
      console.log("file to set",file)
      setProfilePhoto(file); // Set the file to the state
      setProfilePhotoUrl(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };
  let getDriverByIdAndTrips=async()=>{
    try{
    const token=localStorage.getItem("token")
    console.log("Getting Driver By id")
    let response=await axios.get(`https://api.triphog.net/api/v1/driver/getdriver/${id}`,{headers:{'Authorization':token}})
    let _response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
    setFirstName(response.data.driver.firstName)
    setLastName(response.data.driver.lastName)
    setEMailAddress(response.data.driver.EMailAddress)
    setLocation(response.data.driver.location)
    setPhoneNumber(response.data.driver.phoneNumber)
    setVehicleName(response.data.driver.vehicleName)
    setGender(response.data.driver.gender)
    setHourlyPay(response.data.driver.hourlyPay)
    setProfilePhotoUrl(response.data.driver.profilePhotoUrl)
    setSignatureUrl(response.data.driver.signatureUrl)
    setIDCardUrl(response.data.driver.IDCardUrl)
    setIsApproved(response.data.driver.isApproved)
    setLicenseUrl(response.data.driver.licenseUrl)
    setDriverId(response.data.driver._id)
    setPaymentType(response.data.driver.paymentType)
    setPayPerMile(response.data.payPerMile)
  setDocs(response.data.driver.docs)
    let ONGOING=_response.data.trips.filter((trip)=>{
      return(trip.status=="On Route" && trip.driverRef==response.data.driver._id)
    })
    let COMPLETED=_response.data.trips.filter((trip)=>{
      return(trip.status=="Completed" && trip.driverRef==response.data.driver._id)
    })
    let CANCELLED=_response.data.trips.filter((trip)=>{
      return(trip.status=="Cancelled" && trip.driverRef==response.data.driver._id)
    })
    let TOTAL=_response.data.trips.filter((trip)=>{
      return(trip.driverRef==response.data.driver._id)
    })
    console.log(ONGOING.length)
    console.log(CANCELLED)
    console.log(COMPLETED.length)
    ONGOING=ONGOING.length
    CANCELLED=CANCELLED.length
    COMPLETED=COMPLETED.length
    TOTAL=TOTAL.length
    let MyTrips=_response.data.trips.filter((trip)=>{
      return(trip.driverRef==id)
    })
    console.log("My Trips Of Driver With Id",id,"Are",MyTrips)
    let mycompletedRides=MyTrips.filter((trip)=>{
      return(trip.status=="Completed")
    })
    let last24HoursCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=twentyFourHoursAgo)
    })
    let last7DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=sevenDaysAgo)
    })
    let last30DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=thirtyDaysAgo)
    })
    if(mycompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of mycompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setAllTimeRidden(hoursRidden)
    }
    if(last24HoursCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last24HoursCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast24HoursRidden(hoursRidden)

    }
    if(last7DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last7DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast7DaysRidden(hoursRidden)
    }
    if(last30DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last30DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast30DaysRidden(hoursRidden)

    }
    console.log("ON Going",ONGOING)
    setNoOfOnGoingTrips(ONGOING)
    setNofOfCancelledTrips(CANCELLED)
    setNoOfCompletedTrips(COMPLETED)
    setNoOfTotalTrips(TOTAL)

    setSuccessfullyFetched(true) 
  }
  catch(e){
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
  }
  finally{
    setLoading(false)
  }
  }
  const handlePaymentTypeChange=(e)=>{
    setPaymentType(e.target.value)
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
  
    if (token) {
      try {
        const decoded = jwtDecode(token);
  
        // Check if the token is expired
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decoded.exp && decoded.exp > currentTime) {
          // Only call this function once if the token is valid
          getDriverByIdAndTrips();
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        console.log("Invalid token", error);
        navigate("/admin/login");
      }
    } else {
      console.log("No valid token available, user not decoded.");
      navigate("/admin/login");
    }
  }, []); // Ensure this dependency array is empty to avoid infinite loops
  const[decodedUser,setDecodedUser]=useState({});
  useEffect(()=>{
    const token = localStorage.getItem("token");
     
     
     if (token) {
       try {
         const decoded = jwtDecode(token);
     
         // Check if the token is expired
         const currentTime = Date.now() / 1000; // Convert to seconds
         if (decoded.exp && decoded.exp > currentTime) {
           setDecodedUser(decoded)
         } else {
           console.log("Token has expired");
           navigate("/admin/login")
         }
       } catch (error) {
         navigate("/admin/login")
         console.log("Invalid token", error);
       }
     }
     
     if (decodedUser) {
       console.log("Decoded User:", decodedUser);
     } else {
       navigate("/admin/login")
       console.log("No valid token available, user not decoded.");
     }
  },[navigate])
  

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  
  if(decodedUser.role=="User" &&(!checkPermission("Access To Edit Driver")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );

  }
  else{

  if(successfullyFetched==false)
  {
   return( <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <CircularProgress size={60} thickness={5} color="inherit" />
      </div>)
  }
  else
  {
  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <div>
        {loading &&(<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <CircularProgress size={60} thickness={5} color="inherit" />
      </div>)}
      <div className="flex items-center  text-m_c laptop:text-lap_d font-[600] justify-between ">
        {/* <Link to="/admin">
          <div className="flex items-center gap-2">
            <IoArrowBackOutline />
            <h2 className="laptop:text-lap_c text-m-c">{`Driver ${firstName} ${lastName} Details`}</h2>
          </div>
        </Link> */}
        <Button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px"}}>BACK</h5>
          
          </Button>
          <div className="w-full h-auto  flex  flex-col items-center">
  <div className="relative w-[150px] h-[150px] mb-2"> {/* Reduced margin bottom */}
    <img
      src={profilePhotoUrl || 'https://via.placeholder.com/150'}
      alt="Profile"
      className="w-full h-full object-cover rounded-full"
    />
    <label htmlFor="upload_profile" className="absolute bottom-0 right-0 bg-white rounded-full p-2 cursor-pointer">
      <input
        type="file"
        id="upload_profile"
        hidden
        accept="image/*"
        onChange={handlePhotoChange}
      />
      <span className="text-[25px] text-deepBlue">
        <MdLinkedCamera />
      </span>
    </label>
  </div>
  </div>
        
        <div className="grid grid-cols-2 text-lap_b items-center">
         {isApproved?<div>
            <button
              className="bg-vibrantPink text-white font-[400] py-[7px] px-[15px] rounded-lg"
              onClick={() => {
               async function denyDriver(){
                  try{
                    setLoading(true)
                    let response=await axios.put(`https://api.triphog.net/api/v1/admin/denydriver/${id}`)
                    if(response.data.success)
                    {
                      setLoading(false)
                      toast.success("Successfully Approved")
                      getDriverByIdAndTrips()

                    }
                    else{
                      setLoading(false)
                      toast.error("There Was Error While Denying Driver")   
                    }
                  }
                  catch(e)
                  {
                    setLoading(false)
                    toast.error("There Was Error While Denying Driver"+e.message)
  
                  }
                }
                denyDriver()
        
              }}
            >
              Deny Driver
            </button>
          </div>: <div>
            <button className="bg-deepBlue text-white font-[400] py-[7px] px-[15px] rounded-lg"  onClick={() => {
               async function approveDriver(){
                  try{
                    setLoading(true)
                    let response=await axios.put(`https://api.triphog.net/api/v1/admin/approvedriver/${id}`)
                    if(response.data.success)
                    {
                      setLoading(false)
                      toast.success("Successfully Approved")
                      getDriverByIdAndTrips()

                    }
                    else{
                      setLoading(false)
                      toast.error("There Was Error While Denying Driver")   
                    }
                  }
                  catch(e)
                  {
                    setLoading(false)
                    toast.error("There Was Error While Denying Driver")
  
                  }
                }
                approveDriver()
        
              }}>
              Approve Driver
            </button>
          </div>} 

         
        </div>
      
        <h2 className="text-[#4379EE] laptop:text-lap_b text-m_b my-[10px]">
          {/* Monthly {plan} User */}
        </h2>
        {/* <Link>
          <button className="bg-deepBlue text-white laptop:text-lap_b text-m_b cursor-pointer rounded-md  mx-auto py-[5px] px-[30px] justify-center flex">
            {`${firstName} ${lastName}`}
          </button>
        </Link> */}
      </div>
    </div>
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            // onSubmit={handleSubmit}
          >
        
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">First Name</label>
                <br />
                <input
                value={firstName}
                  type="text"
                  
                  // placeholder={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                            value={lastName}
                  type="text"
                  // placeholder={lastName}
                   onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Your Email</label>
                <br />
                <input
            
                  type="text"
                  disabled
                  value={EMailAddress}
                  // placeholder={email}
                  onChange={(event) => {
                    setEMailAddress(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                
                  value={phoneNumber}
                  // placeholder={phoneNumber}
                  onChange={(event) => {
                    setPhoneNumber(event.target.value);
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
            {
              paymentType=="hourly"?<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <label className="">Hourly Pay</label>
              <br />
              <input
                type="number"
                
                value={hourlyPay}
                // placeholder={phoneNumber}
                
                 onChange={(event) => {
                   setHourlyPay(event.target.value);
                 }}
                className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
              />
            </div>:paymentType=="mileage"?<div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Pay Per Mile</label>
                <br />
                <input
                  type="number"
                  
                  value={payPerMile}
                  // placeholder={phoneNumber}
                  
                   onChange={(event) => {
                     setPayPerMile(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>:<></>
            }  
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Gender</label>
                <br />
                <select 
                   onChange={(event) => {
                     setGender(event.target.value);
                   }}
                   value={gender}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Location</label>
                <br />
                <input
                
                  type="text"
                  value={location}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                onChange={(e)=>{
                    setLocation(e.target.value)
                }}
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Vehicle Name</label>
                <br />
                <input
                onChange={(e)=>{
                    setVehicleName(e.target.value)
                }}
                  type="text"
                  value={vehicleName}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
              
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Signature</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e)=>{
                    setSignature(e.target.files[0])
                    setSignatureUrl(URL.createObjectURL(e.target.files[0]))
                  }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {signatureUrl && <img src={signatureUrl} alt="Signature" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>License</label>
                <br />
                <input
                  type="file"
                  accept="image/*"
                 onChange={(e)=>{
                  setLiscense(e.target.files[0])
                  setLicenseUrl(URL.createObjectURL(e.target.files[0]))
                 }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
                {licenseUrl && <img src={licenseUrl} alt="License" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
              
            </div>
            <div className="laptop:w-[45%] mt-[15px] h-[10px] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <FormControl sx={{ width: "100%", height: "12px" }}>
  <InputLabel
    sx={{
      fontFamily: "Cabin",
      fontSize: "14px", // Adjust font size to fit height
      color: "#30325E !important",
      '&.Mui-focused': { color: "#30325E !important" },
      '&:hover': { color: "#30325E !important" }
    }}
  >
    Payment Type
  </InputLabel>

  <Select
    value={paymentType}
    label="Payment Type"
    onChange={handlePaymentTypeChange}
    sx={{
      fontFamily: "Cabin",
      fontSize: "14px", // Adjust font size for smaller height
      lineHeight: "50px", // Align text within the height
      height: "50px", // Set overall height of Select
      marginTop:"10px",
      backgroundColor: "#F4F7FF",
      borderRadius: "8px",
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#9CA3AF",
      },
    }}
  >
    <MenuItem value="hourly" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Hourly Pay</MenuItem>
    <MenuItem value="mileage" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Pay by Mileage</MenuItem>
    <MenuItem value="direct" sx={{ fontFamily: "Cabin", fontSize: "10px" }}>Direct Pay</MenuItem>
  </Select>
</FormControl>

</div>
        
           {/* Add Document Button Section */}
<div className="flex justify-center my-4 laptop:my-[16px] ">
  <div className="w-full max-w-[90%] laptop:w-[45%] py-[18px] text-center">
    <button
      type="button"
      onClick={() => document.getElementById('file-input').click()}
      className="bg-deepBlue text-white rounded-md py-[10px] px-[20px] transition mt-[40px]"
    >
      Add Document
    </button>
    <input
      id="file-input"
      type="file"
      accept="application/pdf"
      onChange={handleDocumentChange}
      className="hidden"
    />
    {documentName && (
      <div className="mt-4 p-4 border rounded-lg bg-gray-50 shadow">
        <span className="text-lg font-semibold">{documentName}</span>
        <div className="flex justify-end mt-2">
          <button
            type="button"
            onClick={cancelDocument}
            className="bg-deepBlue text-white rounded-md px-4 py-2 transition mr-4"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={saveDocument}
            className="bg-deepBlue text-white rounded-md px-4 py-2 transition"
          >
            Save
          </button>
        </div>
      </div>
    )}
  </div>
</div>

{/* Document Preview Section */}
<div className="flex justify-center">
  <div className="w-full max-w-[90%]">
    {docs.length > 0 && (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:gap-6">
        {docs.map((doc, index) => (
          <div
            key={index}
            className="flex flex-col justify-between w-full min-w-[200px] max-w-full h-[350px] border rounded-lg shadow-md p-4 bg-white transition-transform transform hover:scale-105 hover:shadow-lg overflow-hidden"
          >
            <h3 className="text-lg font-bold truncate">{doc.title}</h3>
            <div className="flex justify-between mt-3">
              <button
                type="button"
                className="bg-deepBlue text-white rounded-full px-4 py-2 transition hover:bg-blue-700"
                onClick={() => deleteDoc(doc.Id)} // Prevent immediate execution
              >
                Delete
              </button>
              <button
                type="button"
                className="bg-deepBlue text-white rounded-full px-4 py-2 transition ml-2 hover:bg-blue-700"
                onClick={() => window.open(doc.url, "_blank")} // Open doc in a new window
              >
                View
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>




            <ToastContainer/>
            <div className="flex justify-center items-center">
            {isLoading?<button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]"><CircularProgress style={{color:"white",height:"18px",width:"18px"}} /></button>: <button className="bg-deepBlue text-white laptop:text-lap_c text-m_b cursor-pointer rounded-md py-[7px] px-[40px] mt-[25px]" onClick={(e)=>{
                e.preventDefault()
                updateDriver()
            
                 
               }} >Update</button>}   
              
            
           </div>
          </form>
        </div>
        
      </div>

      <div className="bg-white laptop:mt-[-80px]">
      <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%]">
      
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{noOfTotalTrips}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfOnGoingTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Ongoing</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCompletedTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCancelledTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
          </div>
         
        </div>
      </div>
      <div>
        <h1 className="text-lap_c font-[600] my-[20px]">Hours Ridden</h1>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 24 hours</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last24HoursRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 7 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last7DaysRidden.toFixed(2)}</h2>
          </div>
        </div>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 30 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last30DaysRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">All Time</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{allTimeRidden.toFixed(2)}</h2>
          </div>
        </div>
      </div>
      <div>
       
        <div>
          {/* <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "300px" }}
            className="rounded-lg border"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Driver</Popup>
            </Marker>
          </MapContainer> */}
        </div>
      </div>
    </div>
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
}
  }
};

export default EditDriver;
