import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast,ToastContainer } from 'react-toastify';
const PaymentSuccess = () => {
 let navigate=useNavigate()
 const{token,plan,amount}=useParams()
 console.log("TOKEN",token)
 console.log("PLAN",plan)
 console.log("AMOUNT",amount)
 const decodedUser=jwtDecode(token)
 const[loading,setLoading]=useState(false)

 console.log()
  let updatePaymentHistory=async()=>{
    setLoading(true)
    try{
    let response=await axios.put("https://api.triphog.net/api/v1/admin/update-payment-history",{amount},{headers:{"Authorization":token}})
    console.log(response.data)
    if(response.data.success){
      console.log("Navigating To -2")
      setLoading(false)
      window.location.href="/admin"
    }
    else{
      toast.error("Could Not Update Admin History!Please Try Again And Refresh The Page")
    }
  }
  catch(e){
    toast.error("Could Not Update Admin History!Please Try Again And Refresh The Page")
    

  }
  
  }
  let updateAdmin=async()=>{
    setLoading(true)
    try{
      let response=await axios.put(`https://api.triphog.net/api/v1/superadmin/admin/update/${decodedUser.role=="Admin"?decodedUser.id:""}`,{plan})
      if(response.data.status=="success")
      {
        toast.success("Sucessfully Updated Plan!",{position:"top-center"})
        setLoading(false)
        window.location.href="/admin"
      
      }
      else{
        toast.error("Plan Could Not Be Updated!Please Refresh The Page",{position:"top-center"})
        
      }
    }
    catch(e){
      toast.error("Plan Could Not Be Updated!Please Refresh The Page",{position:"top-center"})


    }
  }
  useEffect(()=>{
    localStorage.setItem("token",token)
    updatePaymentHistory()
    updateAdmin()
  },[])
  if(loading){
   return(<Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"100vh"}}>
    <CircularProgress 
  sx={{
    color: "#30325E",
    width: "30px",
    height: "30px", // Ensure height matches width for proper rendering
  }} 
  thickness={7} // Adjust the thickness value for a bolder look
/>

   </Box>) 
  }
  else{
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <ToastContainer/>
      <Typography variant="h4" color="green">
        Payment Successful! 🎉
      </Typography>
    </Box>
  );
}
};

export default PaymentSuccess;
