import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FaCarSide, FaMapMarkerAlt } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";
import { TbMapPinCancel } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";

const Progress = () => {
  const navigate=useNavigate()
  const [noOfTotalTrips, setNoOfTotalTrips] = useState(0);
  const [noOfCompletedTrips, setNoOfCompletedTrips] = useState(0);
  const [noOfNonResponsiveTrips, setNoOfNonResponsiveTrips] = useState(0);
  const [noOfCancelledTrips, setNoOfCancelledTrips] = useState(0);
  const [noOfNoShowTrips, setNoOfNoShowTrips] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Monthly");

  const getAllTrips = async () => {
    try{
    const token = localStorage.getItem("token");
    let response = await axios.get(
      "https://api.triphog.net/api/v1/trip/gettrips",
      {
        headers: { Authorization: token },
      }
    );

    const trips = response.data.trips;
    const now = new Date();

    let completedTrips = [];
    let nonResponsiveTrips = [];
    let cancelledTrips = [];
    let noShowTrips = [];
    let totalTrips = [];

    const filterTrips = (trip) => {
      const tripDate = new Date(trip.createdAt);
      let isInRange = false;

      if (selectedOption === "Today") {
        // Check if the trip was created today
        isInRange = tripDate.toDateString() === now.toDateString();
        if (isInRange) {
          totalTrips.push(trip);
        }
      } else if (selectedOption === "Monthly") {
        // Check if the trip was created in the last 30 days
        const daysAgo30 = new Date(now);
        daysAgo30.setDate(now.getDate() - 30);
        isInRange = tripDate >= daysAgo30;
        if (isInRange) {
          totalTrips.push(trip);
        }
      } else if (selectedOption === "Yearly") {
        // Check if the trip was created in the last 365 days
        const daysAgo365 = new Date(now);
        daysAgo365.setDate(now.getDate() - 365);
        isInRange = tripDate >= daysAgo365;
        if (isInRange) {
          totalTrips.push(trip);
        }
      } else if (selectedOption === "All") {
        // Include all trips
        isInRange = true;
        totalTrips = response.data.trips;
      }

      return isInRange;
    };

    for (let trip of trips) {
      if (filterTrips(trip)) {
        if (trip.status === "Completed") {
          completedTrips.push(trip);
        } else if (trip.status === "Cancelled") {
          cancelledTrips.push(trip);
        } else if (trip.status === "Non Responsive") {
          nonResponsiveTrips.push(trip);
        } else if (trip.status === "No Show") {
          noShowTrips.push(trip);
        }
      }
    }

    setNoOfTotalTrips(totalTrips.length);
    setNoOfCompletedTrips(completedTrips.length);
    setNoOfCancelledTrips(cancelledTrips.length);
    setNoOfNonResponsiveTrips(nonResponsiveTrips.length);
    setNoOfNoShowTrips(noShowTrips.length);
  }
  catch(e){
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
  }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
        
            if (token) {
              try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Convert to seconds
        
                if (decodedToken.exp && decodedToken.exp > currentTime) {
    getAllTrips();
        
                  
                } else {
                  console.log("Token has expired");
                  navigate("/admin/login");
                }
              } catch (error) {
                console.log("Invalid token", error);
                navigate("/admin/login");
              }
            } else {
              navigate("/admin/login");
            }
  }, [selectedOption]);

  return (
    <div className="bg-white rounded-[20px] py-[20px] px-[20px] mt-[20px] ">
    <ToastContainer/>
      <div className="flex w-full justify-between items-center">
        <select
          className="bg-[#30325E] text-white text-[20px] laptop:text-lap_b laptop:py-[10px] laptop:px-[16px] py-[12px] px-[24px] rounded-[10px] cursor-pointer dashboard_filter transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-300"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="Today">Today</option>
          <option value="Monthly" selected>
            Monthly
          </option>
          <option value="Yearly">Yearly</option>
          <option value="All">All</option>
        </select>
      </div>

      <div className="laptop:grid laptop:grid-cols-5 laptop:gap-[20px] laptop:mt-[20px] grid grid-cols-1 gap-[20px] mt-[25px] boxes_parent">
        <div className="grid grid-cols-[80%_auto] items-center bg-gradient-to-r from-blue-500 to-deepBlue rounded-[15px] laptop:p-[20px] p-[40px] hover:shadow-lg hover:scale-105 transition-all ease-in-out">
          <div>
            <h3 className="laptop:text-lap_b text-[30px] font-semibold text-white">
              Total Trips
            </h3>
            <h1 className="text-[40px] laptop:text-lap_d font-bold text-white">
              {noOfTotalTrips}
            </h1>
          </div>
          <div className="text-white laptop:text-[40px] text-[80px] opacity-70">
            <FaCarSide />
          </div>
        </div>

        <div className="grid grid-cols-[80%_auto] items-center bg-gradient-to-r from-green-500 to-[#0e8269] rounded-[15px] laptop:p-[20px] p-[40px] hover:shadow-lg hover:scale-105 transition-all ease-in-out">
          <div>
            <h3 className="laptop:text-lap_b text-[30px] font-semibold text-white">
              Completed Trips
            </h3>
            <h1 className="text-[40px] laptop:text-lap_d font-bold text-white">
              {noOfCompletedTrips}
            </h1>
          </div>
          <div className="text-white laptop:text-[40px] text-[80px] opacity-70">
            <SiTicktick />
          </div>
        </div>

        <div className="grid grid-cols-[80%_auto] items-center bg-gradient-to-r from-orange-400 to-[#D98634] rounded-[15px] laptop:p-[20px] p-[40px] hover:shadow-lg hover:scale-105 transition-all ease-in-out">
          <div>
            <h3 className="laptop:text-lap_b text-[30px] font-semibold text-white">
              Non Responsive
            </h3>
            <h1 className="text-[40px] laptop:text-lap_d font-bold text-white">
              {noOfNonResponsiveTrips}
            </h1>
          </div>
          <div className="text-white laptop:text-[40px] text-[80px] opacity-70">
            <FaMapMarkerAlt />
          </div>
        </div>

        <div className="grid grid-cols-[80%_auto] items-center bg-gradient-to-r from-red-600 to-[#E42346] rounded-[15px] laptop:p-[20px] p-[40px] hover:shadow-lg hover:scale-105 transition-all ease-in-out">
          <div>
            <h3 className="laptop:text-lap_b text-[30px] font-semibold text-white">
              Cancelled Trips
            </h3>
            <h1 className="text-[40px] laptop:text-lap_d font-bold text-white">
              {noOfCancelledTrips}
            </h1>
          </div>
          <div className="text-white laptop:text-[40px] text-[80px] opacity-70">
            <TbMapPinCancel />
          </div>
        </div>

        <div className="grid grid-cols-[80%_auto] items-center bg-gradient-to-r from-yellow-400 to-[#D98634] rounded-[15px] laptop:p-[20px] p-[40px] hover:shadow-lg hover:scale-105 transition-all ease-in-out">
          <div>
            <h3 className="laptop:text-lap_b text-[30px] font-semibold text-white">
              No Show
            </h3>
            <h1 className="text-[40px] laptop:text-lap_d font-bold text-white">
              {noOfNoShowTrips}
            </h1>
          </div>
          <div className="text-white laptop:text-[40px] text-[80px] opacity-70">
            <FaMapMarkerAlt />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
