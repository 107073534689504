import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import PatientprofileHeader from "./PatientprofileHeader";
import SinglePatientSideBar from "./SinglePatientSideBar";
import Modal from "../../../Modal";
import Rides from "./Rides";
import axios from "axios";
import { Card, CircularProgress } from "@mui/material";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
const position = [51.505, -0.09];

const SinglePatient = () => {
  const[noOfTotalTrips,setNoOfTotalTrips]=useState(0)
  const[noOfOnGoingTrips,setNoOfOnGoingTrips]=useState(0)
  const[noOfCompletedTrips,setNoOfCompletedTrips]=useState(0)
  const[noOfCancelledTrips,setNofOfCancelledTrips]=useState(0)
  const[last24HoursRidden,setLast24HoursRidden]=useState(0)
  const[last7DaysRidden,setLast7DaysRidden]=useState(0)
  const[last30DaysRidden,setLast30DaysRidden]=useState(0)
  const[allTimeRidden,setAllTimeRidden]=useState(0)
  const now=new Date()
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  let navigate=useNavigate()
 const[patient,setPatient]=useState({})
const{id}=useParams()
let getPatientById=async()=>{

  try{


  const token=localStorage.getItem("token")
  let response=await axios.get(`https://api.triphog.net/api/v1/patient/getone/${id}`,{headers:{'Authorization':token}})
  console.log(response.data)
  let _response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
  let ONGOING=_response.data.trips.filter((trip)=>{
    return(trip.status=="On Route" && trip.patientRef==id)
  })
  let COMPLETED=_response.data.trips.filter((trip)=>{
    return(trip.status=="Completed" && trip.patientRef==id)
  })
  let CANCELLED=_response.data.trips.filter((trip)=>{
    return(trip.status=="Cancelled" && trip.patientRef==id)
  })
  let TOTAL=_response.data.trips.filter((trip)=>{
    return(trip.patientRef==id)
  })
  console.log(ONGOING.length)
  console.log(CANCELLED)
  console.log(COMPLETED.length)
  ONGOING=ONGOING.length
  CANCELLED=CANCELLED.length
  COMPLETED=COMPLETED.length
  TOTAL=TOTAL.length
  let MyTrips=_response.data.trips.filter((trip)=>{
    return(trip.patientRef==id)
  })
  console.log("My Trips Of Driver With Id",id,"Are",MyTrips)
  let mycompletedRides=MyTrips.filter((trip)=>{
    return(trip.status=="Completed")
  })
  let last24HoursCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=twentyFourHoursAgo)
  })
  let last7DaysCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=sevenDaysAgo)
  })
  let last30DaysCompletedRides=mycompletedRides.filter((trip)=>{
    return(new Date(trip.createdAt)>=thirtyDaysAgo)
  })
  if(mycompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of mycompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setAllTimeRidden(hoursRidden)
  }
  if(last24HoursCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last24HoursCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast24HoursRidden(hoursRidden)

  }
  if(last7DaysCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last7DaysCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast7DaysRidden(hoursRidden)
  }
  if(last30DaysCompletedRides.length>0)
  {
    let hoursRidden=0
    for(let trip of last30DaysCompletedRides)
    {
      hoursRidden=hoursRidden+trip.timeTaken
    }
    setLast30DaysRidden(hoursRidden)

  }
  console.log("ON Going",ONGOING)
  setNoOfOnGoingTrips(ONGOING)
  setNofOfCancelledTrips(CANCELLED)
  setNoOfCompletedTrips(COMPLETED)
  setNoOfTotalTrips(TOTAL)

  setPatient(response.data.patient)
}
catch(e){
  toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bold"}})
}
}
useEffect(()=>{
  const token = localStorage.getItem("token");
       
      
       
       if (token) {
         try {
           const decoded = jwtDecode(token);
       
           // Check if the token is expired
           const currentTime = Date.now() / 1000; // Convert to seconds
           if (decoded.exp && decoded.exp > currentTime) {
    getPatientById()
            
           } else {
             console.log("Token has expired");
             navigate("/admin/login")
           }
         } catch (error) {
           navigate("/admin/login")
           console.log("Invalid token", error);
         }
       }
       
     else {
         navigate("/admin/login")
         console.log("No valid token available, user not decoded.");
       }
  
},[])
const[decodedUser,setDecodedUser]=useState({});

useEffect(()=>{
  const token = localStorage.getItem("token");
     
     
  if (token) {
    try {
      const decoded = jwtDecode(token);
  
      // Check if the token is expired
      const currentTime = Date.now() / 1000; // Convert to seconds
      if (decoded.exp && decoded.exp > currentTime) {
        setDecodedUser(decoded)
      } else {
        console.log("Token has expired");
        navigate("/admin/login")
      }
    } catch (error) {
      navigate("/admin/login")
      console.log("Invalid token", error);
    }
  }
  
  if (decodedUser) {
    console.log("Decoded User:", decodedUser);
  } else {
    navigate("/admin/login")
    console.log("No valid token available, user not decoded.");
  }
},[navigate])

 

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  if(decodedUser.role=="User" &&(!checkPermission("Access To View Patient")))
  {
    return (
      <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
          <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
          <p className="text-gray-700 mb-6">
            You do not have access to this page. Please contact the administrator or go back.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  else{
if(Object.keys(patient).length==0)
{
  return ( <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <CircularProgress size={60} thickness={5} color="inherit" />
  </div>)
}
else
{
  return (
    <>
    
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",marginTop:"1px"}}>BACK</h5>
          
          </button>
          <div className="flex flex-col justify-center items-center">
            <img src={patient.profilePhotoUrl} style={{height:"150px",width:"150px"}} className="rounded-full" />
          </div>
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            // onSubmit={handleSubmit}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">First Name</label>
                <br />
                <input
                disabled
                value={patient.firstName}
                  type="text"
                  // placeholder={firstName}
                  // onChange={(event) => {
                  //   setFirstName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                disabled
                value={patient.lastName}
                  type="text"
                  // placeholder={lastName}
                  // onChange={(event) => {
                  //   setLastName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Your Email</label>
                <br />
                <input
                disabled
                  type="text"
                  value={patient.EMailAddress}
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                disabled
                value={patient.phoneNumber}
                  type="text"
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Location</label>
                <br />
                <input
                disabled
                value={patient.location}
                  type="text"
                  
                  // onChange={(event) => {
                  //   setLocation(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Age</label>
                <br />
                <input
                disabled
                  type="number"
                 value={patient.age}
                  // onChange={(event) => {
                  //   setAge(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Gender</label>
                <br />
                <select
                disabled
                value={patient.gender}
                  //  onChange={(event) => {                  
                  //    setGender(event.target.value);
                  //  }}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option selected>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
              <label>Signature</label>
              <img
                src={patient.signatureUrl}
                alt="Signature"
                className="h-[120px] w-[120px]"
              />
            </div>
            </div>
            
         
          </form>
        </div>
        {/* <Rides /> */}
      </div>

      <div className="bg-white laptop:mt-[-80px]">
      <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%]">
        {/* <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div> */}
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{noOfTotalTrips}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfOnGoingTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Ongoing</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCompletedTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCancelledTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
          </div>
        
        </div>
      </div>
      <div>
        <h1 className="text-lap_c font-[600] my-[20px]">Hours Ridden</h1>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 24 hours</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last24HoursRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 7 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last7DaysRidden.toFixed(2)}</h2>
          </div>
        </div>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 30 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last30DaysRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">All Time</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{allTimeRidden.toFixed(2)}</h2>
          </div>
        </div>
      </div>
      <div>
        {/* <h2 className="text-lap_c font-[800] my-[10px]">Live Tracking</h2>
        <div>
          <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "300px" }}
            className="rounded-lg border"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Driver</Popup>
            </Marker>
          </MapContainer>
        </div> */}
      </div>
    </div>
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
}
  }
};

export default SinglePatient;
