import React, { useEffect, useState } from "react";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FaCarSide } from "react-icons/fa6";
import { toast,ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const DriverSideBar = (props) => {
  const navigate=useNavigate()
  const[loading,setLoading]=useState(true)
  
  const[allTrips,setAllTrips]=useState([])
  const[assginedTrips,setAssignedTrips]=useState([])
  const[notAssignedTrips,setNotAssignedTrips]=useState([])
  const[nonResponsiveTrips,setNonResponsiveTrips]=useState([])
  const[noShowTrips,setNoShowTrips]=useState([])
  const[completedTrips,setCompletedTrips]=useState([])
  const[cancelledTrips,setCancelledTrips]=useState([])
  const[onRouteTrips,setOnRouteTrips]=useState([])
  let getAndSetTrips=async()=>{
    setLoading(true)
    const token=localStorage.getItem("token")
    console.log("Getting Trips")
  try{
    let response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
    console.log(response.data)
    setAllTrips(response.data.trips)
    setAssignedTrips(response.data.trips.filter((trip)=>{
      return (trip.status=="Assigned")
    }))
    setNotAssignedTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Not Assigned")
    }))
    setNonResponsiveTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Non Responsive")
    }))
    setCompletedTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Completed")
    }))
    setNoShowTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="No Show")
    }))
    setCancelledTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Cancelled")
    }))
    setOnRouteTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="On Route")
    }))
  }
  catch(e)
  {
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bolder"}})
  }
  finally{
    setLoading(false)
  }



  }
  useEffect(()=>{
    const token = localStorage.getItem("token");
    
        if (token) {
          try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Convert to seconds
    
            if (decodedToken.exp && decodedToken.exp > currentTime) {
    getAndSetTrips()
              
            } else {
              console.log("Token has expired");
              navigate("/admin/login");
            }
          } catch (error) {
            console.log("Invalid token", error);
            navigate("/admin/login");
          }
        } else {
          navigate("/admin/login");
        }
  },[props])
  if(loading){
    return(
      <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen ">
      <div className="flex justify-center items-center h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
          </div>
          </div>
          
    )
  }
  else{
  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white min-h-screen ">
      <div className="w-[100%]">
        {/* <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div> */}
        <ToastContainer/>
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{allTrips.length}</h2>
        <div className="grid  items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{assginedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Assigned</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{onRouteTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">On Route</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{completedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{cancelledTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{notAssignedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Not Assigned</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{nonResponsiveTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Non Responsive</span>
            </div>
          </div>
          {/* <h2>Chart</h2> */}
        </div>
      </div>
    </div>
  );
}
};

export default DriverSideBar;
