import React, { useRef } from "react";
import { CiMenuKebab, CiSearch } from "react-icons/ci";
import { MdOutlineCloudDownload, MdOutlineFileUpload } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { FaPencil } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { RiDeleteBin5Line } from "react-icons/ri";
import Pagination from "@mui/material/Pagination";
import {
  Dialog,
  CircularProgress,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import "react-datepicker/dist/react-datepicker.css";
import RideData from "./UsersData";
import { useState } from "react";
import RideSideBar from "./UsersSideBar";
import axios from "axios";
const Users = () => {
  const [refreshStatistics, setRefreshStatistics] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [filter, setFilter] = useState("all time");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  let navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const usersPerPage = 25;
  const [userId, setUserId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  let deleteUser = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let response = await axios.delete(
      `https://api.triphog.net/api/v1/user/deleteuser/${userId}`,
      { headers: { Authorization: token } }
    );
    if (response.data.success) {
      setRefreshStatistics(true);
      toast.success("Successfully Deleted!", { position: "top-center" });
      setCurrentPage(1)
      getAllUsers(filter, 1);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Error While Deleting!", { postion: "top-center" });
    }
    console.log(response.data);
  };
  let deleteSelected = async () => {
    console.log("selected users ids", selectedUsersIds);
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      console.log("Array", typeof selectedUsersIds);

      let response = await axios.delete(
        `https://api.triphog.net/api/v1/user/deleteselected`,
        {
          headers: { Authorization: token },
          data: { selectedUsersIds: selectedUsersIds },
        }
      );

      if (response.data.success) {
        setSelectedUsersIds([]);
        setCurrentPage(1)
        getAllUsers(filter, 1);
        setLoading(false);
        setRefreshStatistics(true);

        toast.success("Successfully Deleted!", { position: "top-center" });
      } else {
        setLoading(false);
        toast.error("Error While Deleting Drivers!", {
          position: "top-center",
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Error While Deleting Drivers!");
    }
  };
  let handleDateChange = async (date, page) => {
    try{
    setSelectedDate(date);
    setIsDateSelected(true);
    setIsSearchEnabled(false);
    setLoading(true);
    const formattedDate = date.toISOString().split("T")[0];
    console.log(formattedDate);
    let response = await axios.get(
      `https://api.triphog.net/api/v1/user/getusersbydate/${formattedDate}?limit=${usersPerPage}&page=${page}`,
      { headers: { Authorization: localStorage.getItem("token") } }
    );
    console.log(response.data);
    setUsers(response.data.users);
    setTotalPages(response.data.totalPages);
    setLoading(false);}
    catch(e){
      toast.error("Error While Filtering Users By Date. Please Try Again")
      setLoading(false)
    }
  };

  let getAllUsers = async (selectedFilter, page) => {
    setLoading(true);
    try{
    setIsSearchEnabled(false);
    let decodedUser = jwtDecode(localStorage.getItem("token"));
    console.log("Decoded User Data", decodedUser);
    const token = localStorage.getItem("token");
    let response = await axios.get(
      `https://api.triphog.net/api/v1/user/getallusers?limit=${usersPerPage}&page=${page}&filter=${selectedFilter}`,
      { headers: { Authorization: token } }
    );
    let _response = await axios.get(
      `https://api.triphog.net/api/v1/user/getallusers`,
      { headers: { Authorization: token } }
    );
    console.log(response.data);
    setUsers(response.data.users);
    setAllUsers(_response.data.users);
    setTotalPages(response.data.totalPages);
    setLoading(false);
  }
  catch(e){
    toast.error("Error While Getting Users!Please Try Again Or Refresh The Page")
    setLoading(false)
  }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          getAllUsers("all time", 1);
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        console.log("Invalid token", error);
        navigate("/admin/login");
      }
    } else {
      navigate("/admin/login");
    }
  }, [navigate]);
  const handleClose = async (filter) => {
    setAnchorEl(null);
  };
  const tableRef = useRef(null);
  //   const { setQuery, query } = useAllAdminsContext();
  const printTable = () => {
    const tableData = document.querySelector("table").cloneNode(true);
    const rows = tableData.querySelectorAll("tr");

    // Remove the "Actions" column from each row
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      if (cells.length > 5) {
        row.removeChild(cells[cells.length - 1]);
      }
    });

    const printWindow = window.open("", "", "height=800,width=800");
    printWindow.document.write(
      "<html><head><title>Print Table</title></head><body>"
    );
    printWindow.document.write(tableData.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px] user-page">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedUsersIds.length > 0 ? (
            <button
              onClick={deleteSelected}
              className="bg-deepBlue text-white mb-[10px] h-[30px] w-[150px] rounded-lg"
            >
              Delete Selected
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="flex  justify-between items-center w-[100%] filter_parent">
          <Link
            to={"/admin/addnewuser"}
            className=" float-right bg-[#30325E] text-[white] text-[25px] laptop:text-lap_b laptop:py-[8px] px-[26px] laptop:px-[14px] py-[15px] rounded-[10px] cursor-pointer add_new_user_btn"
          >
            Add New User
          </Link>
          {/* <h1 className="laptop:text-lap_c text-[40px] font-[600] mt-[30px]">Users</h1> */}
          <div className="gap-[20px] filter_input_p ">
            <div>
              <div className="float-right flex gap-[15px] filter_input">
                <input
                  className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length == 0) {
                      console.log("Setting Search Enable To False");
                      setIsSearchEnabled(false);
                    } else {
                      setIsSearchEnabled(true);
                    }
                    setSearchQuery(e.target.value);
                    let searchResults = allUsers.filter((user) => {
                      return (
                        user.firstName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase()) ||
                        user.lastName
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                    });

                    const indexOfLastRecord = 1 * usersPerPage;
                    const indexOfFirstRecord = indexOfLastRecord - usersPerPage;

                    // Get the records for the current page
                    const recordsToShow = searchResults.slice(
                      indexOfFirstRecord,
                      indexOfLastRecord
                    );

                    // Calculate total number of pages
                    const totalPages = Math.ceil(
                      searchResults.length / usersPerPage
                    );
                    setCurrentPage(1);
                    setTotalPages(totalPages);
                    setUsers(recordsToShow);
                  }}
                  placeholder={((<CiSearch />), " Search by Name")}
                  //   onChange={(event) => {
                  //     setQuery({ ...query, name: event.target.value });
                  //   }}
                />

                <div className="user_page_filter">
                  <div>
                    <div className="float-left flex gap-[15px]">
                      <DatePicker
                        className="border-[#E4E4E4] border-solid border-[1.4px] rounded-[10px] text-[25px] laptop:text-lap_b text-[#B0B0B0] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] focus:border-[#E4E4E4] focus:border-solid focus:border-[1.4px] focus:rounded-[10px]"
                        selected={selectedDate}
                        onChange={(date) => {
                          handleDateChange(date,1);
                        }}
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>

                  <button
                    variant="contained"
                    onClick={handleClick}
                    className="bg-deepBlue text-white h-[35px] w-[100px] rounded-lg"
                  >
                    <FilterListIcon />
                    Filter
                  </button>
                 
                </div>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose(null)}
                  PaperProps={{
                    style: {
                      maxHeight: "200px",
                      width: "250px",
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "today" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("today");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllUsers("today",1);
                    }}
                  >
                    <h5>Today</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "weekly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("weekly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllUsers("weekly", 1);
                    }}
                  >
                    <h5>Last 7 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "monthly" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("monthly");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllUsers("monthly", 1);
                    }}
                  >
                    <h5>Last 30 Days</h5>
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontFamily: "Cabin",
                      backgroundColor:
                        filter === "all time" &&
                        isDateSelected == false &&
                        isSearchEnabled == false
                          ? "#30325E"
                          : "none",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setFilter("all time");
                      handleClose(null);
                      setIsDateSelected(false);
                      setIsSearchEnabled(false);
                      setCurrentPage(1)
                      getAllUsers("all time", 1);
                    }}
                  >
                    <h5>All Time</h5>
                  </MenuItem>
                </Menu>
                {/* <button className=" bg-[white] text-[#30325E] p-[12px] laptop:py-[8px] laptop:px-[14px] py-[15px] px-[26px] rounded-[10px] text-[25px] laptop:text-lap_b cursor-pointer  border-[1.4px] border-[#E4E4E4] border-solid">
                  <CiMenuKebab />
                </button> */}
              </div>
            </div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
        <div>
          <div>
            {loading && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <CircularProgress size={60} thickness={5} color="inherit" />
              </div>
            )}
            <ToastContainer />
            <Dialog open={isDialogOpen}>
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DialogTitle style={{ color: "red" }}>
                  Do You Really Want To Delete?
                </DialogTitle>
                <DialogActions>
                  <Button
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      deleteUser();
                      setIsDialogOpen(false);
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      setIsDialogOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
            <div className="overflow-x-auto mt-[20px]">
              <table className="min-w-full border-separate border-spacing-0 border-[2px] border-solid border-[#B0B0B0] rounded-lg shadow-sm overflow-hidden">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {/* <input type="checkbox" /> */}
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User Name
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ph. No
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User Status
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  {users.map((user) => {
                    return (
                      <>
                        <tr className="cursor-pointer">
                          <td class="px-2 py-4 whitespace-nowrap">
                            <input
                              type="checkbox"
                              checked={selectedUsersIds.includes(user._id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  let isSelected = false;
                                  for (let selectedUserId of selectedUsersIds) {
                                    if (selectedUserId == user._id) {
                                      isSelected = true;
                                    }
                                  }
                                  if (isSelected) {
                                    let _selectedUsersIds =
                                      selectedUsersIds.filter(
                                        (selectedUserId) => {
                                          return selectedUserId != user._id;
                                        }
                                      );
                                    console.log(
                                      "Selected Users Ids",
                                      _selectedUsersIds
                                    );
                                    setSelectedUsersIds(_selectedUsersIds);
                                  } else {
                                    let _selectedUsersIds =
                                      selectedUsersIds.concat(user._id);
                                    console.log(
                                      "Selected Users Ids",
                                      _selectedUsersIds
                                    );

                                    setSelectedUsersIds(_selectedUsersIds);
                                  }
                                } else {
                                  let isSelected = false;
                                  for (let selectedUserId of selectedUsersIds) {
                                    if (selectedUserId == user._id) {
                                      isSelected = true;
                                    }
                                  }
                                  if (isSelected) {
                                    let _selectedUsersIds =
                                      selectedUsersIds.filter(
                                        (selectedUserId) => {
                                          return selectedUserId != user._id;
                                        }
                                      );
                                    console.log(
                                      "Selected Users Ids",
                                      _selectedUsersIds
                                    );

                                    setSelectedUsersIds(_selectedUsersIds);
                                  }
                                }
                              }}
                            />
                          </td>
                          <td class="px-2 py-4 whitespace-nowrap">
                            <div
                              class="ml-4"
                              onClick={() => {
                                navigate(`/admin/user/${user._id}`);
                              }}
                            >
                              <div class="text-sm font-medium text-gray-900">
                                {`${user.firstName + " " + user.lastName}`}
                              </div>
                            </div>
                          </td>
                          <td
                            class="px-2 py-4 whitespace-nowrap"
                            onClick={() => {
                              navigate(`/admin/user/${user._id}`);
                            }}
                          >
                            <h2>{user.EMailAddress}</h2>
                          </td>
                          <td
                            class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                            onClick={() => {
                              navigate(`/admin/user/${user._id}`);
                            }}
                          >
                            {user.phoneNumber}
                          </td>

                          <td
                            class="py-4 px-2 border-b border-gray-200 "
                            onClick={() => {
                              navigate(`/admin/user/${user._id}`);
                            }}
                          >
                            <span
                              class={` ${
                                user.status === "Not Active"
                                  ? "text-[#E42346] bg-[#DF367033]"
                                  : user.status === "Active"
                                  ? "text-[#409261] bg-[#E9FFEF]"
                                  : "text-[#D98634] bg-[#FFF2DD]"
                              } flex  items-center py-2 px-2 rounded-full text-xs justify-center`}
                            >
                              <GoDotFill /> {user.status}
                            </span>
                          </td>
                          <td class="px-2 py-4 whitespace-nowrap text-[20px] font-medium flex gap-4 pt-[30px] ">
                            <FaPencil
                              onClick={() => {
                                navigate(`/admin/user/edit/${user._id}`);
                              }}
                            />

                            <span className="text-[red] cursor-pointer">
                              <RiDeleteBin5Line
                                onClick={() => {
                                  setUserId(user._id);
                                  setIsDialogOpen(true);
                                }}
                              />
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center items-center mt-6">
              <div className="flex justify-center my-5">
                <Pagination
                  count={totalPages} // Total number of pages
                  page={currentPage} // Current active page
                  onChange={(event, value) => {
                    setCurrentPage(value); // Update the page
                    if (isSearchEnabled) {
                      let searchResults = allUsers.filter((user) => {
                        return (
                          user.firstName
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          user.lastName
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        );
                      });

                      const indexOfLastRecord = value * usersPerPage;
                      const indexOfFirstRecord =
                        indexOfLastRecord - usersPerPage;

                      // Get the records for the current page
                      const recordsToShow = searchResults.slice(
                        indexOfFirstRecord,
                        indexOfLastRecord
                      );

                      // Calculate total number of pages
                      const totalPages = Math.ceil(
                        searchResults.length / usersPerPage
                      );

                      setTotalPages(totalPages);
                      setUsers(recordsToShow);
                    } else if (isDateSelected) {
                      handleDateChange(selectedDate, value);
                    } else {
                      getAllUsers(filter, value); // Fetch drivers for the new page
                    }
                  }}
                  variant="outlined"
                  color="primary"
                />
              </div>
            </div>
            {/* {status && (
        <Modal status={status} setStatus={setStatus} message={modalMessage} />
      )} */}
          </div>
        </div>
      </div>
      <div className="mt-[-80px] h-screen">
        <RideSideBar refreshStatistics={refreshStatistics} />
      </div>
    </>
  );
};

export default Users;
