import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { jwtDecode } from 'jwt-decode';

const DriverPaymentDetails = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);
  const location = useLocation();
  const driver = location.state?.driver;
  const dateRange = location.state?.dateRange;
  const [loading, setLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [timePeriod, setTimePeriod] = useState('All Time');
  const [filteredPayments, setFilteredPayments] = useState([]);
  const[type,setType]=useState(location.state?.driver.paymentType)
  console.log("Location Data On Detail Page",location.state)
  console.log("Payment Type",type)
  const [AMOUNTPAID,setAmountPaid]=useState(location.state?.AMOUNTPAID)
  const[AMOUNTTOBEPAID,setAmountToBePaid]=useState(location.state?.AMOUNTTOBEPAID)
  const downloadRef = useRef(null); // Ref for capturing specific section

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  let getDriversWhoDrove = async () => {
    try {
      const token=localStorage.getItem("token")
      let response = await axios.get(`https://api.triphog.net/api/v1/driver/getdriven/${dateRange[0]}/${dateRange[1]}`,{headers:{"Authorization":token}});
      if (response.data.success) {
        let _selectedDriver = response.data.driversWhoDrove.find((_driver) => _driver._id === driver._id);
        console.log("Getting The Selected Driver",_selectedDriver)
        setSelectedDriver(_selectedDriver);
        filterPaymentsByDate(_selectedDriver.paymentHistory, timePeriod); // Filter the payments initially
      }
    } catch (e) {
      toast.error("Something went wrong while getting driver details");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
         
        
         
         if (token) {
           try {
             const decoded = jwtDecode(token);
         
             // Check if the token is expired
             const currentTime = Date.now() / 1000; // Convert to seconds
             if (decoded.exp && decoded.exp > currentTime) {
    getDriversWhoDrove();
               
              

             } else {
               console.log("Token has expired");
               navigate("/admin/login")
             }
           } catch (error) {
             navigate("/admin/login")
             console.log("Invalid token", error);
           }
         }
         
         else {
           navigate("/admin/login")
           console.log("No valid token available, user not decoded.");
         }
      
  }, [timePeriod]);

  const handlePaymentSubmit = async () => {
    setLoading(true); // Show loading spinner

    const token = localStorage.getItem("token");
    let totalPending=0
     totalPending= type=="hourly"?(selectedDriver.hoursRidden * selectedDriver.hourlyPay) - selectedDriver.amountPaid:type=="mileage"?selectedDriver.milesDriven*selectedDriver.payPerMile-selectedDriver.amountPaid:AMOUNTTOBEPAID-AMOUNTPAID

    if (Number(amount) > totalPending) {
      toast.warn("Amount cannot be greater than pending amount", { position: "top-center" });
   setLoading(false)
    } else {
      try {
        
        
        let response = await axios.post(
          `https://api.triphog.net/api/v1/driver/pay/${selectedDriver._id}`,
          { amount: Number(amount),type },
          { headers: { Authorization: token } }
        );

        if (response.data.success) {
          if(type=="direct")
          {
            console.log("AmountPaid",AMOUNTPAID)
            console.log("type of amount paid",typeof(AMOUNTPAID))
            console.log("Paying amount",amount)
            setAmountPaid(Number(AMOUNTPAID)+Number(amount))
            getDriversWhoDrove();
          }
          else{
          getDriversWhoDrove();
          }
          toast.success("Successfully Paid!", { position: "top-center" });
        } else {
          toast.error("Something went wrong while paying!");
        }
      
      } catch (e) {
        toast.error("Something went wrong while paying!");
      }
      finally{
    setLoading(false); // Hide loading spinner


      }
    }
  };

  // Filter payment history based on the selected time period
  const filterPaymentsByDate = (payments, period) => {
    const now = new Date();
    let filtered = [];

    switch (period) {
      case 'Today':
        filtered = payments.filter(payment => {
          const paymentDate = new Date(payment.date);
          return paymentDate.toDateString() === now.toDateString();
        });
        break;
      case 'Last 7 Days':
        filtered = payments.filter(payment => {
          const paymentDate = new Date(payment.date);
          return now - paymentDate <= 7 * 24 * 60 * 60 * 1000; // Last 7 days
        });
        break;
      case 'Last 30 Days':
        filtered = payments.filter(payment => {
          const paymentDate = new Date(payment.date);
          return now - paymentDate <= 30 * 24 * 60 * 60 * 1000; // Last 30 days
        });
        break;
      case 'All Time':
      default:
        filtered = payments;
        break;
    }
    setFilteredPayments(filtered);
  };

  useEffect(() => {
    if (selectedDriver.paymentHistory) {
      filterPaymentsByDate(selectedDriver.paymentHistory, timePeriod);
    }
  }, [timePeriod, selectedDriver]);

  // Function to handle download as PDF
  const handleDownload = async () => {
    const buttonsToHide = document.querySelectorAll('.hide-on-pdf'); // Select elements with this class
    buttonsToHide.forEach(btn => btn.style.display = 'none'); // Hide them

    if (downloadRef.current) {
      try {
        // Convert the specified section to PNG image
        const dataUrl = await toPng(downloadRef.current);

        // Create a PDF using jsPDF
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Add the image to the PDF
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);

        // Download the PDF
        pdf.save('driver-payment-details.pdf');
      } catch (error) {
        console.error('Error generating PDF:', error);
        toast.error("Failed to download the document.");
      }
    }

    // Show the hidden buttons again after generating the PDF
    buttonsToHide.forEach(btn => btn.style.display = ''); // Reset display to default
  };

  if (Object.keys(selectedDriver).length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="loader border-t-4 border-deepBlue rounded-full w-16 h-16 animate-spin"></div>
      </div>
    );
  } else {
    return (
      <div className="w-[90%] mx-auto mt-10">
        <div className="bg-white p-6 shadow-md rounded-md">
          <button
            className="bg-deepBlue text-white px-6 py-2 rounded-md hide-on-pdf"
            onClick={handleBack}
          >
            Back
          </button>

          {/* The section to be downloaded as PDF */}
          <div ref={downloadRef}>
            <h2 className="text-2xl font-bold mt-4">Driver Payment Detail</h2>
            {selectedDriver ? (
              <>
                <h3 className="text-xl mt-4">Payment Details for {selectedDriver.firstName}</h3>

                {/* Dropdown for filtering by date range */}
                <div className="mt-4">
                  <label htmlFor="timePeriod" className="mr-2">Show Payments for: </label>
                  <select
                    id="timePeriod"
                    className="border p-2 rounded"
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                  >
                    <option value="Today">Today</option>
                    <option value="Last 7 Days">Last 7 Days</option>
                    <option value="Last 30 Days">Last 30 Days</option>
                    <option value="All Time">All Time</option>
                  </select>
                </div>

                <table className="table-auto w-full mt-4 text-left border-collapse">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="p-2 border">Date</th>
                      <th className="p-2 border">Amount</th>
                      <th className="p-2 border">Status</th>
                      <th className="p-2 border">Type</th>

                    </tr>
                  </thead>
                  <tbody>
                    {filteredPayments.map((payment, index) => (
                      <tr key={index}>
                        <td className="p-2 border">{new Date(payment.date).toLocaleDateString()}</td>
                        <td className="p-2 border">${payment.amount}</td>
                        <td className="p-2 border">{payment.status}</td>
                        <td className="p-2 border">{payment.type}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No payment details available.</p>
            )}

            <div className="mt-6 bg-gray-50 p-6 rounded-md">
              <h3 className="text-xl mb-4">Driver Information</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <strong>Name:</strong>
                  <p>{selectedDriver.firstName}</p>
                </div>
                <div>
                  <strong>{type=="hourly"?"Hours Driven":type=="mileage"?"Miles Driven":""}</strong>
                  <p>{type=="hourly"?selectedDriver.hoursRidden:type=="mileage"?selectedDriver.milesDriven:""}</p>
                </div>
                <div>
                  <strong>Total Amount:</strong>
                  <p>${type=="hourly"?selectedDriver.hoursRidden * selectedDriver.hourlyPay:type=="mileage"?selectedDriver.milesDriven*selectedDriver.payPerMile:AMOUNTTOBEPAID}</p>
                </div>
                <div>
                  <strong>Amount Paid:</strong>
                  <p>${selectedDriver.paymentType=="direct"?AMOUNTPAID:selectedDriver.amountPaid}</p>
                </div>
                <div>
                  <strong>Pending Amount:</strong>
                  <p>
  ${
    selectedDriver.paymentType === "hourly"
      ? Math.max(
          (selectedDriver.hoursRidden * selectedDriver.hourlyPay) -
            selectedDriver.amountPaid,
          0
        ).toFixed(2)
      : selectedDriver.paymentType === "mileage"
      ? Math.max(
          (selectedDriver.milesDriven * selectedDriver.payPerMile) -
            selectedDriver.amountPaid,
          0
        ).toFixed(2)
      : AMOUNTTOBEPAID-AMOUNTPAID
  }
</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <label className="hide-on-pdf">Amount to Pay: </label>
            <input
              type="number"
              className="border p-2 rounded hide-on-pdf"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button
              className="bg-deepBlue text-white px-6 py-2 rounded ml-4 hide-on-pdf"
              onClick={handlePaymentSubmit}
              disabled={loading}
            >
              {loading ? "Processing..." : "Pay Now"}
            </button>
          </div>

          <button
            className="bg-deepBlue text-white px-6 py-2 rounded mt-4 hide-on-pdf"
            onClick={handleDownload}
          >
            Download PDF
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default DriverPaymentDetails;
