import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TimelineIcon from "@mui/icons-material/Timeline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DevicesIcon from "@mui/icons-material/Devices";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ChatIcon from "@mui/icons-material/Chat";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";

function ChoosePlan()
{
    const {token}=useParams()
    const[loading,setLoading]=useState(false)


    const basicFeatures = [
        "Easy trip uploads",
        "Instant signature capture for patients and drivers",
        "Automatic Log Creation with GPS timestamp",
        "GPS to track Drivers on Route",
        "Patient app",
        "Drivers app",
        "Messaging feature to admin office",
        "24/7 technical support",
      ];
    
      const proFeatures = [
        ...basicFeatures,
        "Document storage for admin and Drivers",
        "HR and Payroll",
      ];
      let makePayment=async(amount)=>
        {
          setLoading(true)
          console.log("Making Payment")
        const stripe=  await loadStripe("pk_test_51N3JXaIt4uj8sEIFyp8aviPUcgeur8tSktgkwQSDlCwV1xgRM6qXhqBQKzp7qAkNenvNGWZGXjVTkrS6mz1i8d4d00Ljiw8inK")
       let response= await axios.post("https://api.triphog.net/api/v1/admin/create-checkout-session",{amount,plan:amount==199?"Basic":"Pro",token})
       console.log(response.data)
       let result=await stripe.redirectToCheckout({sessionId:response.data.id})
       if(result.error)
       {
        console.log("Error While Checkout")
        setLoading(false)
       }
       else{
        
      
       }
        }
      
    return(
        <div className="bg-deepBlue text-white py-16 px-6">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">Choose the Right Plan for You</h2>
          <p className="text-lg mb-12">
            Select the perfect plan to manage your medical transportation needs effectively.
          </p>
  
          <div className="grid md:grid-cols-2 gap-8">
            {/* Basic Plan */}
            <div className="border border-gray-300 rounded-lg bg-white text-deepBlue p-8 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4">Basic Plan</h3>
              <p className="text-lg mb-6">Includes essential features for trip management.</p>
              <div className="mb-6">
                {basicFeatures.map((feature, index) => (
                  <div key={index} className="flex items-center mb-3">
                    <span className="bg-deepBlue text-white rounded-full p-2 mr-3">
                      <CloudUploadIcon fontSize="small" />
                    </span>
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
              <button onClick={()=>{
                makePayment(249)
              }} className="w-full bg-deepBlue text-white py-3 rounded-lg font-bold hover:bg-blue-700">
                Choose Basic($249)
              </button>
            </div>
  
            {/* Pro Plan */}
            <div className="border border-gray-300 rounded-lg bg-white text-deepBlue p-8 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-2xl font-bold mb-4">Pro Plan</h3>
              <p className="text-lg mb-6">
                Includes everything in Basic plus advanced features.
              </p>
              <div className="mb-6">
                {proFeatures.map((feature, index) => (
                  <div key={index} className="flex items-center mb-3">
                    <span className="bg-deepBlue text-white rounded-full p-2 mr-3">
                      <CloudUploadIcon fontSize="small" />
                    </span>
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
              <button onClick={()=>{
                makePayment(279)
              }} className="w-full bg-deepBlue text-white py-3 rounded-lg font-bold hover:bg-blue-700">
                Choose Pro($279)
              </button>
            </div>
          </div>
        </div>
      </div>

    )
}
export default ChoosePlan