import { IoArrowBackOutline } from "react-icons/io5";
import { MdLinkedCamera } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { FaPencil } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

// import Modal from "../../../Modal";

const UserDetail = () => {
   // const { setFeatures, features } = useAddNewAdminContext();
   const[firstName,setFirstName]=useState("")
   const[lastName,setLastName]=useState("")
   const[EMailAddress,setEMailAddress]=useState("")
   const[phoneNumber,setPhoneNumber]=useState("")
   const [dummyfeatures, setDummyFeatures] = useState(["Access to financial data","Access to system settings","Access to subscriber information","Access to feedback management"]);
   const [newFeature, setNewFeature] = useState("");
   const[companyName,setCompanyName]=useState("")
   const[profilePhoto,setProfilePhoto]=useState('')
   const[user,setUser]=useState({})
   const [selectedPermissions, setSelectedPermissions] = useState([]);
   const permissions = [
    'Access To Add Driver',
    'Access To Delete Driver',
    'Access To Edit Driver',
    'Access To Approve Driver',
    "Access To View Drivers",
    "Access To View Driver",
    'Access To Add Trip',
    'Access To Delete Trip',
    'Access To Edit Trip',
    'Access To Upload Trips',
    "Access To Download Logs",
    "Access To Assign Trip",
    "Access To View Trips",
    "Access To View Trip",
    'Access To Add Patient',
    'Access To Delete Patient',
    'Access To Edit Patient',
    'Access To View Patients',
    "Access To View Patient",
    "Access To Payrol"
    
    
  ];
  const[isSubAdmin,setIsSubAdmin]=useState(false)
   const handleCheckboxChange = (permission) => {
     if (selectedPermissions.includes(permission)) {
       setSelectedPermissions(selectedPermissions.filter(item => item !== permission));
     } else {
       setSelectedPermissions([...selectedPermissions, permission]);
     }
   };
   let navigate=useNavigate()
 
   const{Id}=useParams()
   let getUserById=async()=>{
   let decodedUser=jwtDecode(localStorage.getItem("token"))
    const token=localStorage.getItem("token")
    let response=await axios.get(`https://api.triphog.net/api/v1/user/getuserbyId/${Id}`,{headers:{'Authorization':token}})

if(decodedUser.role=="User")
{
  setIsSubAdmin(true)
}
    console.log(response.data)
    setFirstName(response.data.user.firstName)
    setLastName(response.data.user.lastName)
    setEMailAddress(response.data.user.EMailAddress)
    setPhoneNumber(response.data.user.phoneNumber)
    setCompanyName(response.data.user.companyName)
    console.log("User profile Photo Url",response.data.user.profilePhotoUrl)
    setProfilePhoto(response.data.user.profilePhotoUrl)
    console.log("user Accessiblities",response.data.user.accessibilities)
    setSelectedPermissions(response.data.user.accessibilities)
   }
   useEffect(()=>{
    const token = localStorage.getItem("token");
      
     
      
      if (token) {
        try {
          const decoded = jwtDecode(token);
      
          // Check if the token is expired
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decoded.exp && decoded.exp > currentTime) {
    getUserById()
      
          } else {
            console.log("Token has expired");
            navigate("/admin/login")
          }
        } catch (error) {
          navigate("/admin/login")
          console.log("Invalid token", error);
        }
      }
      
       else {
        navigate("/admin/login")
        console.log("No valid token available, user not decoded.");
      }

   },[])

   const [editingIndex, setEditingIndex] = useState(-1);
 
   const handleAddFeature = () => {
     if (newFeature.trim()) {
       setDummyFeatures([...dummyfeatures, newFeature.trim()]);
       // setFeatures([...dummyfeatures, newFeature.trim()]);
       setNewFeature("");
     }
   };
 
   const handleEditFeature = (index) => {
     setEditingIndex(index);
     setNewFeature(dummyfeatures[index]);
   };
 
   const handleDeleteFeature = (index) => {
     setDummyFeatures(dummyfeatures.filter((_, i) => i !== index));
     // setFeatures(features.filter((_, i) => i !== index));
   };
 
   const handleFeatureChange = (e) => {
     setNewFeature(e.target.value);
   };
 
   const handleSaveEdit = () => {
     if (newFeature.trim()) {
       const updatedFeatures = [...dummyfeatures];
       updatedFeatures[editingIndex] = newFeature.trim();
       setDummyFeatures(updatedFeatures);
       // setFeatures(updatedFeatures);
       setEditingIndex(-1);
       setNewFeature("");
     }
   };
   const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  }; 
  const[decodedUser,setDecodedUser]=useState({})
  useEffect(() => {
    const token = localStorage.getItem("token");
  
    if (token) {
      try {
        const decoded = jwtDecode(token);
  
        // Check if the token is expired
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decoded.exp && decoded.exp > currentTime) {
          setDecodedUser(decoded);
          console.log("Decoded User:", decoded);
        } else {
          console.log("Token has expired");
          navigate("/admin/login");
        }
      } catch (error) {
        console.log("Invalid token", error);
        navigate("/admin/login");
      }
    } else {
      console.log("No valid token available, user not decoded.");
      navigate("/admin/login");
    }
  }, [navigate]);
      if (decodedUser) {
        console.log("Decoded User:", decodedUser);
      } else {
        navigate("/admin/login")
        console.log("No valid token available, user not decoded.");
      }
 
  if(decodedUser.role=="User")
    {
     return (
       <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
         <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
           <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
           <p className="text-gray-700 mb-6">
             You do not have access to this page. Please contact the administrator or go back.
           </p>
           <button
             onClick={handleGoBack}
             className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
           >
             Go Back
           </button>
         </div>
       </div>
     );
    }
    else{
  return (
    <>
      <div className="laptop:h-screen">
        <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
        <button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px",marginTop:"1px"}}>BACK</h5>
          
          </button>
          <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%"}}>
         <img style={{height:"150px",width:"150px"}} className="rounded-full" src={profilePhoto} />

          </div>
          <form
            className="text-darkgray laptop:text-lap_b text-[30px]"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">First Name</label>
                <br />
                <input
                disabled
                  type="text"
                  value={firstName}
                  placeholder="Enter your First Name"
                   onChange={(event) => {
                   setFirstName(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                disabled
                value={lastName}
                  type="text"
                  placeholder="Enter your Last Name"
                   onChange={(event) => {
                     setLastName(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%] ">
                <label className="">Your Email</label>
                <br />
                <input
                disabled
                value={EMailAddress}
                  type="text"
                  placeholder="jaskolski.brent@gmail.com"
                   onChange={(event) => {
                     setEMailAddress(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                disabled
                value={phoneNumber}
                  type="text"
                  placeholder="546-933-2772"
                   onChange={(event) => {
                     setPhoneNumber(event.target.value);
                   }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
             
            </div>
            
          </form>
        </div>
      </div>
      <div className="mt-[-100px] bg-[white]">
      <div className="laptop:grid laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%] mt-[10px]">
        <div className="laptop:flex float-right text-lap_e cursor-pointer hidden laptop:pt-[50px]">
          <IoNotificationsOutline />
        </div>
        
        
         
       
        <div
            
            className="grid grid-cols-[80%_20%] items-center border rounded-lg px-[10px] py-[20px] laptop:text-lap_b text-[35px] mt-[20px]"
          >
            <div className="p-4">
      <h3 className="text-xl font-bold mb-4">Select Permissions</h3>
      <div className="flex flex-wrap gap-4">
        {permissions.map((permission, index) => (
          <div key={index} className="flex items-center space-x-2">
            <input
              type="checkbox"
              id={`permission-${index}`}
              value={permission}
              checked={selectedPermissions.includes(permission)}
              onChange={() => handleCheckboxChange(permission)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label htmlFor={`permission-${index}`} className="text-sm">
              {permission}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-6">
        <h4 className="text-lg font-semibold">Selected Permissions:</h4>
        <ul className="list-disc list-inside mt-2">
          {selectedPermissions.map((permission, index) => (
            <li key={index} className="text-sm">{permission}</li>
          ))}
        </ul>
      </div>
    </div>

        </div> 

            
    </div>
    </div>
      </div>
      {/* {status && (
        <Modal status={status} setStatus={setStatus} message={message} />
      )} */}
    </>
  );
}
};

export default UserDetail;
