import React, { useState } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import SettingSideBar from "../SettingSideBar";
import { toast,ToastContainer } from "react-toastify";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { jwtDecode } from "jwt-decode";

function ChangePasswordAdmin(){
  const[showOldPassword, setShowOldPassword] = useState(false);
  const[showNewPassword, setShowNewPassword] = useState(false);
  const[showConfirmPassword, setShowConfirmPassword] = useState(false);
  const[oldPassword,setOldPassword]=useState("")
  const[newPassword,setNewPassword]=useState("")
  const[confirmPassword,setConfirmPassword]=useState("")
  const[loading,setLoading]=useState(false)
  const token=localStorage.getItem("token")
  const navigate=useNavigate()
  
     
     let decodedUser = {};
     
     if (token) {
       try {
         const decoded = jwtDecode(token);
     
         // Check if the token is expired
         const currentTime = Date.now() / 1000; // Convert to seconds
         if (decoded.exp && decoded.exp > currentTime) {
           decodedUser = decoded;
         } else {
           console.log("Token has expired");
           navigate("/admin/login")
         }
       } catch (error) {
         navigate("/admin/login")
         console.log("Invalid token", error);
       }
     }
     
     if (decodedUser) {
       console.log("Decoded User:", decodedUser);
     } else {
       navigate("/admin/login")
       console.log("No valid token available, user not decoded.");
     }
  
  let changePassword=async()=>{
  try{
    if(oldPassword.length==0||newPassword.length==0||confirmPassword.length==0){
 toast.warn("Missing Fields!",{position:"top-center"})
    }
   else if(newPassword!=confirmPassword)
    {
      toast.error("Passwords Do Not Match!",{position:"top-center"})
    }
    else
    {
      setLoading(true)
      let response =await axios.post("https://api.triphog.net/api/v1/admin/changepassword",{newPassword,currentPassword:oldPassword,email:localStorage.getItem("adminEMailAddress")})
      if(response.data.success)
      {
        toast.success("Succcessfully Changed!",{position:"top-center"})
        setLoading(false)

      }
      else if(response.data.success==false && response.data.message=="InCorrect Old Password")
      {
        toast.error("Incorrect Old Password!",{position:"top-center"})
        setLoading(false)
      
      }
      else{
        toast.error("Error While Changing Password!",{position:"top-center"})
        setLoading(false)
      
      }
    }
    }
    catch(e){
    toast.error("Error While Changing  Password!")
    }
    finally{
    setLoading(false)
    }
  }
  if(!token)
    {
      return(<div className="flex flex-col justify-center items-center">
        PLEASE LOGIN TO CONTINUE
      </div>)
    }
    else if(decodedUser.role=="User")
    {
      return(
        <div className="flex flex-col justify-center items-center">
          YOU CANNOT ACCESS THIS PAGE
        </div>
      )
    }
    else{
  
  return (
    <>
    
      <div className="h-screen">
        <ToastContainer/>
        <div className="bg-white rounded-[20px] p-[10%] mt-[20px] mr-[30px]">
          <h1 className="text-center font-[700] text-[25px] my-[20px]">
            Change Password
          </h1>
          <div>
            <label className="">Old Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={showOldPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                onChange={(e)=>{
                  setOldPassword(e.target.value)
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={() => {
                  setShowOldPassword(!showOldPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showOldPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div>
            <label className="">New Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter New Password"
                onChange={(e)=>{
                  setNewPassword(e.target.value)
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div>
            <label className="">Confirm Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
                onChange={(e)=>{
                  setConfirmPassword(e.target.value)
                }}
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div className="text-center">
            {
              loading?<CircularProgress/>:<Link to={"/superadmin"}>
              <button className="div items-center justify-center px-[50px] bg-deepBlue text-white laptop:py-2 py-4 rounded-lg transition-colors duration-300 laptop:text-[17px] text-[40px]" onClick={(e)=>{
                e.preventDefault()
                changePassword()
              }}>
                Save
              </button>
            </Link>
            }

          </div>
        </div>
      </div>
    </>
  );}
}

export default ChangePasswordAdmin
