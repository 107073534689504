import React, { useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import SettingSideBar from "./SettingSideBar";
import { toast,ToastContainer } from "react-toastify";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
const ChangePassword = () => {
  const[previousPassword,setPreviousPassword]=useState("")
  const[latestPassword,setLatestPassword]=useState("")
  const[confirmationPassword,setConfirmationPassword]=useState("")
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const[superAdminEMailAddress,setSuperAdminEMailAddress]=useState("")
  const navigate=useNavigate()
  
  const[loading,setLoading]=useState(false)
  let getEMailAddress=async()=>{
    try{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/getsuperadmin",{headers:{"Authorization":localStorage.getItem("superadmintoken")}})
    console.log("Change pass super admin",response.data)
    setSuperAdminEMailAddress(response.data.superAdmin.EMailAddress)
    }
    catch(e){
      toast.error("Error While Getting Super Admin")
    }
  }
  let changePassword=async()=>{
    try{
    if(latestPassword!=confirmationPassword)
    {
      toast.error("Passwords That Not Match!")
    }
    else{
      setLoading(true)
      //call the api here
      let response=await axios.put("https://api.triphog.net/api/v1/superadmin/changepassword",{email:superAdminEMailAddress,currentPassword:previousPassword,newPassword:latestPassword})
      console.log(response.data)
      if(response.data.success)
      {
        toast.success("Password Changed Successfully")
      setLoading(false)
      }

      else if(response.data.success==false && response.data.message=="InCorrect Old Password")
      {
 toast.error("Incorrect Old Password")
setLoading(false)      
}
      else{
        toast.error("There Was An Error While Changing Password")
      setLoading(false)
      }
    }
  }
  catch(e){
    toast.error("Password Could Not Be Updated!Please Try Again.")

  } 
  finally{
    setLoading(false)
  }

  }
  useEffect(()=>{
    const token = localStorage.getItem("superadmintoken");
            
                if (token) {
                  try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // Convert to seconds
            
                    if (decodedToken.exp && decodedToken.exp > currentTime) {
    getEMailAddress()
        
            
                      
                    } else {
                      console.log("Token has expired");
                      navigate("/superadmin/login");
                    }
                  } catch (error) {
                    console.log("Invalid token", error);
                    navigate("/superadmin/login");
                  }
                } else {
                  navigate("/superadmin/login");
                }
  },[])
  return (
    <>
      <div className="h-screen">
        <ToastContainer/>
        <div className="bg-white rounded-[20px] p-[10%] mt-[20px] mr-[30px]">
          <h1 className="text-center font-[700] text-[25px] my-[20px]">
            Change Password
          </h1>
          <div>
            <label className="">Old Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={oldPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                onChange={(e)=>{
                  setPreviousPassword(e.target.value)
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                                onClick={() => {
                  
                  setOldPassword(!oldPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {oldPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div>
            <label className="">New Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={newPassword ? "text" : "password"}
                onChange={(e)=>{
                  setLatestPassword(e.target.value)
                }}
                placeholder="Enter New Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={() => {
                  setNewPassword(!newPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {newPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div>
            <label className="">Confirm Password</label>
            <div className="laptop:mb-4 mb-8 relative laptop:text-[15px] text-[40px] my-[10px]">
              <input
                type={confirmPassword ? "text" : "password"}
                placeholder="Confirm your Password"
                onChange={(e)=>{
                  setConfirmationPassword(e.target.value)
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 laptop:pl-[40px] pl-[90px]"
              />
              <span className="absolute inset-y-0 left-0  flex items-center laptop:text-[20px] text-[40px] text-gray-400  laptop:pl-[10px] pl-[30px]">
                <CiLock />
              </span>
              <button
                type="button"
                onClick={() => {
                  setConfirmPassword(!confirmPassword);
                }}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
              >
                {confirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
              </button>
            </div>
          </div>
          <div className="text-center">
            <Link >
              <button disabled={loading} className="div items-center justify-center px-[50px] bg-deepBlue text-white laptop:py-2 py-4 rounded-lg transition-colors duration-300 laptop:text-[17px] text-[40px]" onClick={changePassword}>
                {loading?<CircularProgress style={{height:"15px",width:"15px",color:"white"}} />:"Save"}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white mt-[-80px]">
        <SettingSideBar />
      </div>
    </>
  );
};

export default ChangePassword;
