import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Wellcome = () => {
  const navigate=useNavigate()
  const[superAdmin,setSuperAdmin]=useState({})
  let getSuperAdmin=async()=>{
    try{
    let response=await axios.get("https://api.triphog.net/api/v1/superadmin/getsuperadmin",{headers:{"Authorization":localStorage.getItem("superadmintoken")}})
    console.log("Super Admin",response.data)
    setSuperAdmin(response.data.superAdmin)
    }
    catch(e){
      toast.error("Super Admin Could Not Be Fetched!")
    }
  }
  useEffect(()=>{
    const token = localStorage.getItem("superadmintoken");
            
                if (token) {
                  try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000; // Convert to seconds
            
                    if (decodedToken.exp && decodedToken.exp > currentTime) {
    getSuperAdmin()
        
            
                      
                    } else {
                      console.log("Token has expired");
                      navigate("/superadmin/login");
                    }
                  } catch (error) {
                    console.log("Invalid token", error);
                    navigate("/superadmin/login");
                  }
                } else {
                  navigate("/superadmin/login");
                }
  },[])
  if(Object.keys(superAdmin).length==0)
  {
    return(<Box className="flex flex-col justify-center items-center">
      <CircularProgress style={{color:"#30325E",height:"18px",width:"18px"}} thickness={5} />
    </Box>)
  }
  else{
  return (
    <div className="laptop:grid laptop:grid-cols-[8%_auto] laptop:items-center hidden bg-[#F4F7FF] laptop:px-[30px] laptop:pt-[8px]">
    <ToastContainer/>
      <div className="bg-[#C8F7DC] h-[60px] w-[60px] rounded-full flex flex-col justify-center items-center">
      <h1>{superAdmin.firstName[0]}</h1>
    
      </div>
      <div>
        <h1 className="text-[16px] text-[#262626]">
          <b>Welcome Back</b>, {superAdmin.firstName+" "+superAdmin.lastName}👋{" "}
        </h1>
        <h3 className="text-[10px] text-[#26262657] font-[400]">Super Admin</h3>
      </div>
    </div>
  );
}
};

export default Wellcome;
