import axios from "axios";
import React, { useEffect } from "react";

import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb";
import { useState } from "react";
import { toast,ToastContainer } from "react-toastify";

const HomeSideBar = (props) => {
  const[isDriverMissing,setIsDriverMissing]=useState(false)
  const[driverTotalRides,setDriverTotalRides]=useState([])
  const[driverCompletedRides,setDriverCompletedRides]=useState([])
  const[driverOnGoingRides,setDriverOnGoingRides]=useState([])
  const[driverCancelledRides,setDriverCancelledRides]=useState([])
  const[allTrips,setAllTrips]=useState([])
  const[assginedTrips,setAssignedTrips]=useState([])
  const[notAssignedTrips,setNotAssignedTrips]=useState([])
  const[nonResponsiveTrips,setNonResponsiveTrips]=useState([])
  const[noShowTrips,setNoShowTrips]=useState([])
  const[completedTrips,setCompletedTrips]=useState([])
  const[cancelledTrips,setCancelledTrips]=useState([])
  const[onRouteTrips,setOnRouteTrips]=useState([])
  const[last24HoursRidden,setLast24HoursRidden]=useState(0)
  const[last7DaysRidden,setLast7DaysRidden]=useState(0)
  const[last30DaysRidden,setLast30DaysRidden]=useState(0)
  const[allTimeRidden,setAllTimeRidden]=useState(0)
  console.log("Driver Side Bar  DRIVER SIDE BAR",props)
  const[loading,setLoading]=useState(true)
  const token=localStorage.getItem("token")
  let getTripsAndSetStatistics=async()=>{
    console.log("Getting Trips And Setting Stats")
    setLoading(true)
    const now=new Date()
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    try{
    let response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
   
    let MyTrips=response.data.trips.filter((trip)=>{
      return(trip.driverRef==props.driverId)
    })
    setDriverTotalRides(MyTrips)
    setDriverCancelledRides(MyTrips.filter((trip)=>{
      return(trip.status=="Cancelled")
    }))
    setDriverOnGoingRides(MyTrips.filter((trip)=>{
      return(trip.status=="On Route")
    }))
    setDriverCompletedRides(MyTrips.filter((trip)=>{
      return(trip.status=="Completed")
    }))
    let mycompletedRides=MyTrips.filter((trip)=>{
      return(trip.status=="Completed")
    })
    let last24HoursCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=twentyFourHoursAgo)
    })
    let last7DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=sevenDaysAgo)
    })
    let last30DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=thirtyDaysAgo)
    })
    if(mycompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of mycompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setAllTimeRidden(hoursRidden)
    }
    if(last24HoursCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last24HoursCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast24HoursRidden(hoursRidden)

    }
    if(last7DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last7DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast7DaysRidden(hoursRidden)
    }
    if(last30DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last30DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast30DaysRidden(hoursRidden)

    }
  }
  catch(e){
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bolder"}})
  }
  finally{
    setLoading(false)
  }

    
  }
  let getTripStatistics=async()=>{
    console.log("Getting Trip Stats")
    setLoading(true)
    try{
    let response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
    console.log("Trips Data",response.data)
    setAllTrips(response.data.trips)
    setAssignedTrips(response.data.trips.filter((trip)=>{
      return (trip.status=="Assigned")
    }))
    setNotAssignedTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Not Assigned")
    }))
    setNonResponsiveTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Non Responsive")
    }))
    setCompletedTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Completed")
    }))
    setNoShowTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="No Show")
    }))
    setCancelledTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="Cancelled")
    }))
    setOnRouteTrips(response.data.trips.filter((trip)=>{
      return(trip.status=="On Route")
    }))
  }
  catch(e){
    toast.error(e.message,{style:{fontFamily:"Cabin",fontWeight:"bolder"}})
  }
  finally{
 setLoading(false)
  }
  }
  useEffect(()=>{
    getTripStatistics()
  },[])
  useEffect(()=>{
    if(props.driverId.length==0)
    {
      console.log("Driver Id Is Missing")
      setIsDriverMissing(true)
    }
    else{
      console.log("Getting Trips And Statistics")
    getTripsAndSetStatistics()
    }
  },[props])
  if(loading)
  {
    return(
      <div className="flex justify-center items-center h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-deepBlue"></div>
          </div>
    )
  }
  else{
  return (
    <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%]">
        {/* <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div> */}
        <ToastContainer/>
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{allTrips.length}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[5%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#30325E] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{assginedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Assigned</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#096C86] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{onRouteTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">On Route</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{completedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{cancelledTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#cccccc] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{notAssignedTrips.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Not Assigned</span>
            </div>
          </div>
          {/* <div>Chart</div> */}
        </div>
      </div>
      <div className="w-[100%]">
        <h2 className="text-lap_c mt-[20px] font-[600]">
           Driver Trips
        </h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{isDriverMissing?0:driverTotalRides.length}</h2>
        <div className="grid grid-cols-2 items-center gap-[5%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#30325E] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{isDriverMissing?0:driverOnGoingRides.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Ongoing</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#096C86] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{isDriverMissing?0:driverCompletedRides.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>

            <div className="flex items-center my-[10px]">
              <h3 className="text-[#cccccc] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{isDriverMissing?0:driverCancelledRides.length}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
          </div>
          {/* <div>Chart</div> */}
        </div>
      </div>
      <div>
        <h1 className="text-lap_c font-[600] my-[20px]">Hours Ridden</h1>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 24 hours</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{isDriverMissing?0:last24HoursRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 7 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{isDriverMissing?0:last7DaysRidden.toFixed(2)}</h2>
          </div>
        </div>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 30 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{isDriverMissing?0:last30DaysRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">All Time</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{isDriverMissing?0:allTimeRidden.toFixed(2)}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
};

export default HomeSideBar;
